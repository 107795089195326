import React, { PropsWithChildren } from "react";
import RowAnt, { RowProps } from "antd/es/grid/row";
import { ClassValue, clsx } from "clsx";

interface OwnProps extends Omit<RowProps, "className"> {
  className?: ClassValue;
  column?: boolean;
  nowrap?: boolean;
}

type Props = OwnProps;

const Row = (props: PropsWithChildren<Props>) => {
  const { column = false, nowrap = false, ...rowProps } = props;

  return <RowAnt {...rowProps} className={clsx(props.className, { "direction-column": column, "nowrap": nowrap })} />;
};

export default Row;
