import React, { Fragment, PropsWithChildren } from "react";
import ContentWrapper from "shared/ui/content-wrapper/ContentWrapper";
import Panel from "../typography/Panel";
import Skeleton from "../skeleton/Skeleton";

interface OwnProps {}

type Props = OwnProps;

const Fallback = (props: PropsWithChildren<Props>) => {
  return (
    <Fragment>
      {/*<TitleWrapper>*/}
      {/*  <Skeleton round paragraph={{ rows: 1 }} />*/}
      {/*</TitleWrapper>*/}
      <ContentWrapper>
        <Panel>
          <Skeleton title paragraph={{ rows: 5 }} />
        </Panel>
      </ContentWrapper>
    </Fragment>
  );
};

export default Fallback;
