import packageInfo from "../../../package.json";
import { Env } from "types/enums/env";
import { Environment } from "types/interfaces/environment";
import { Language } from "types/enums/language";

// Параметры запуска
const ENV: Env = (process.env.REACT_APP_ENV as Env) || Env.Prod;
const LANGUAGE: Language = (process.env.REACT_APP_LANGUAGE as Language) || Language.Russian;

// Переопределения под стенды
const ENVIRONMENTS: { [key in Env]: Partial<Environment> } = {
  [Env.Local]: {
    SENTRY_DSN: null,
    YANDEX_METRIC_ENABLE: false
    // Быстрый переход на Qa
    // API_URL: process.env.PUBLIC_URL + "/qa/api",
  },
  [Env.Dev]: {
    YANDEX_METRIC_ENABLE: false
  },
  [Env.Qa]: {},
  [Env.Prod]: {
    YANDEX_METRIC_NUMBER: "88909313" // https://metrika.yandex.ru/settings?id=88909313
  }
};

const environment: Environment = {
  ENV,
  LANGUAGE,
  VERSION: packageInfo?.version,
  API_URL: process.env.PUBLIC_URL + "/api",
  BASENAME: process.env.PUBLIC_URL,
  YANDEX_METRIC_ENABLE: true,
  YANDEX_METRIC_NUMBER: null,
  SENTRY_DSN: null,
  ...ENVIRONMENTS[ENV]
};

export default environment;
