import { api } from "./api";
import { ReportFileUrl } from "types/models/reports";
import { PageRequest } from "types/interfaces/pageResponse";
import { EventPageWrapper } from "types/models/audit/eventPageWrapper";
import { EventPage } from "types/models/audit/eventPage";
import { AuditFiltersType, AuditSortsType } from "types/interfaces/audit";
import { ActorListWrapper } from "types/models/audit/actorListWrapper";
import { SectionListWrapper } from "types/models/audit/sectionListWrapper";
import { FileUrlWrapper } from "types/models/audit/fileUrlWrapper";
import { ActorList } from "types/models/audit/actorList";
import { Section } from "types/models/audit/section";

const prefix = "/audit/public/hub/v1";
export const auditApiUrl = {
  events: `${prefix}/events/all`,
  section: `${prefix}/section/all`,
  actors: `${prefix}/actors/all`,
  eventsDownloadUrl: `${prefix}/events/download-url`
};

export const auditApi = api.injectEndpoints({
  endpoints: (build) => ({
    auditEvents: build.query<EventPage, PageRequest<AuditFiltersType, AuditSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: auditApiUrl.events,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: EventPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "AuditEvent", id } as const)) || []),
        { type: "AuditEvent", id: "LIST" }
      ]
    }),
    auditSection: build.query<Array<Section>, void>({
      query: () => auditApiUrl.section,
      transformResponse: (response: SectionListWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [
        ...(_result?.map(({ name }) => ({ type: "AuditSection", id: name } as const)) || []),
        { type: "AuditSection", id: "LIST" }
      ]
    }),
    auditActors: build.query<ActorList, void>({
      query: () => auditApiUrl.actors,
      transformResponse: (response: ActorListWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [
        ...(_result?.map(({ id }) => ({ type: "AuditActors", id } as const)) || []),
        { type: "AuditActors", id: "LIST" }
      ]
    }),
    auditEventsDownloadUrl: build.mutation<ReportFileUrl, PageRequest<AuditFiltersType, AuditSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: auditApiUrl.eventsDownloadUrl,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data
    })
  }),
  overrideExisting: false
});

export const { useAuditEventsQuery, useAuditSectionQuery, useAuditActorsQuery, useAuditEventsDownloadUrlMutation } =
  auditApi;
