import { api } from "./api";
import {
  Credential,
  ClientPageWrapper,
  ClientWrapper,
  CredentialWrapper,
  DepartmentPageWrapper,
  DepartmentWrapper,
  ClientPage,
  DepartmentPage
} from "types/models/user";
import { CredentialPageWrapper } from "types/models/user/credentialPageWrapper";
import { CredentialPage } from "types/models/user/credentialPage";
import { CredentialFiltersType, CredentialSortsType } from "types/interfaces/user";
import { PageRequest } from "types/interfaces/pageResponse";

const prefix = `/crm/public/hub/user-model/v1`;
export const userApiUrl = {
  me: `${prefix}/me`,
  client: (id: string) => `${prefix}/clients/${id}`,
  department: (id: string) => `${prefix}/departments/${id}`,
  clients: `${prefix}/clients/all`,
  departments: `${prefix}/departments/all`,
  credentials: `${prefix}/credential/all`
};

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    me: build.query<Credential, void>({
      query: () => ({
        url: userApiUrl.me
      }),
      transformResponse: (response: CredentialWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "User" }]
    }),
    client: build.query<any, string>({
      query: (id) => ({
        url: userApiUrl.client(id)
      }),
      transformResponse: (response: ClientWrapper, meta, arg) => response.data
    }),
    department: build.query<any, string>({
      query: (id) => ({
        url: userApiUrl.department(id)
      }),
      transformResponse: (response: DepartmentWrapper, meta, arg) => response.data
    }),

    // TODO(redux): дублируются запросы clientList departmentList credentialList и в старой версии, но можно переделать на query с кэшом
    clientList: build.query<ClientPage, PageRequest>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: userApiUrl.clients,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: ClientPageWrapper, meta, arg) => response.data
    }),
    departmentList: build.query<DepartmentPage, PageRequest>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: userApiUrl.departments,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: DepartmentPageWrapper, meta, arg) => response.data
    }),
    credentialList: build.query<CredentialPage, PageRequest<CredentialFiltersType, CredentialSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: userApiUrl.credentials,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: CredentialPageWrapper, meta, arg) => response.data
    })
  })
});

export const { useLazyMeQuery, useLazyClientListQuery, useLazyDepartmentListQuery, useLazyCredentialListQuery } =
  userApi;
