import {
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  formatISO,
  isPast,
  isThisYear,
  isToday,
  isValid,
  isYesterday,
  parse,
  parseISO,
  subDays
} from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { MONTHS_SHORT } from "shared/config/date";
import environment from "shared/config/environment";
import { Language } from "types/enums/language";
import { translation } from "shared/ui/translation/translation";

const locale: Locale = environment.LANGUAGE === Language.English ? enUS : ru;

export const stringToDate = (dateString: string, formatString: string = "dd.MM.yyyy"): Date => {
  const date = parse(dateString, formatString, new Date());

  if (isNaN(date as any)) {
    console.log("Date Parse error:", dateString, formatString);
  }

  return date;
};

export const dateToServer = (date: Date = new Date(), formatString: string = "yyyy-MM-dd"): string => {
  return dateToString(date, formatString);
};

export const dateToString = (date: Date = new Date(), formatString: string = "dd.MM.yyyy", options?: any): string => {
  let res = "";

  try {
    res = format(date, formatString, options);
  } catch (e) {
    console.log("Date Format error:", date, e);
  }

  return res;
};

export const stringDateToServer = (
  dateString: string,
  formatString: string = "dd.MM.yyyy",
  toFormat: string = "yyyy-MM-dd"
): string => {
  return dateToServer(stringToDate(dateString, formatString), toFormat);
};

export const serverDateToString = (
  dateString: string | undefined,
  formatString: string = "yyyy-MM-dd",
  toFormat: string = "dd.MM.yyyy"
): string | undefined => {
  return dateString && dateToString(stringToDate(dateString, formatString), toFormat, { locale });
};

export const isValidStringDate = (dateString: string, formatString: string = "dd.MM.yyyy"): boolean => {
  return isValid(stringToDate(dateString, formatString));
};

export const localeDate = (date: Date, formatLocale: string = "d MMMM yyyy"): string => {
  return dateToString(date, formatLocale, { locale });
};

export const localeMonthDate = (
  date: Date,
  year: boolean = true,
  time: boolean = false,
  seconds: boolean = false
): string => {
  const month = localeDate(date, "M");
  const monthShortName = translation(MONTHS_SHORT[parseInt(month)] || "");

  return localeDate(
    date,
    `d '${monthShortName}'${year ? " yyyy" : ""}${time ? ", H:mm" : ""}${time && seconds ? ":ss" : ""}`
  );
};

export const localeStringDate = (
  dateString: string,
  formatString: string = "yyyy-MM-dd",
  formatLocale: string = "d MMMM yyyy"
): string => {
  return dateToString(stringToDate(dateString, formatString), formatLocale, { locale });
};

// ISO не учитываем отклонение
export const getISONoDeviation = (dateString: string): string => {
  const match = dateString.match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{1,}[.\d]{0,})[+-]\d{2}:\d{2}/);

  return match?.[1] || dateString;
};

export const ISODateToDate = (dateString: string, formatLocale: string = "d MMMM yyyy"): Date => {
  const ISODate = getISONoDeviation(dateString);
  return parseISO(ISODate);
};

export const stringToISODate = (
  dateString: string,
  formatString: string = "dd.MM.yyyy",
  noDeviation = true
): string => {
  const date = stringToDate(dateString, formatString);
  const ISO = formatISO(date);
  return noDeviation ? getISONoDeviation(ISO) : ISO;
};

export const ISODateToView = (
  dateString: string,
  time: boolean = false,
  seconds: boolean = false,
  shorts: boolean = true
): string => {
  const date = ISODateToDate(dateString);

  if (shorts) {
    let timeStr = `H:mm${seconds ? ":ss" : ""}`;

    if (isToday(date)) {
      return localeDate(date, `'${translation("Сегодня в")}' ` + timeStr);
    } else if (isYesterday(date)) {
      return localeDate(date, `'${translation("Вчера в")}' ` + timeStr);
    }
  }

  return localeMonthDate(date, !isThisYear(date), time, seconds);
};

export const ISODateToFormat = (dateString: string, formatLocale: string = "d MMMM yyyy"): string => {
  return localeISODate(getISONoDeviation(dateString), formatLocale);
};

export const localeISODate = (dateString: string, formatLocale: string = "d MMMM yyyy"): string => {
  return dateString && dateToString(parseISO(dateString), formatLocale, { locale });
};

export const distanceToDate = (
  startDate: string,
  finishDate: string,
  formatString: string = "dd.MM.yyyy",
  addSuffix = false,
  unit?: any
): string | undefined => {
  if (!startDate || !finishDate) {
    return;
  }

  const finish = stringToDate(finishDate, formatString);
  let start = stringToDate(startDate, formatString);

  if (unit === "day") {
    start = subDays(start, 1);
  }

  return formatDistanceStrict(finish, start, { locale, unit, addSuffix });
};

export const distanceToDateTime = (
  startDate: string,
  startTime = "00:00",
  finishDate: string,
  finishTime = "23:59",
  addSuffix = false
): string | undefined => {
  const start = `${startDate} ${startTime}`;
  const finish = `${finishDate} ${finishTime}`;
  const format = "dd.MM.yyyy HH:mm";

  return distanceToDate(start, finish, format, addSuffix);
};

export const distanceToNow = (dateString: string, formatString: string = "dd.MM.yyyy", addSuffix = false): string => {
  return dateString && formatDistanceToNow(stringToDate(dateString, formatString), { locale, addSuffix });
};

export const distanceToNowTime = (dateString: string, timeString: string, addSuffix = false): string | undefined => {
  const dateTime = `${dateString} ${timeString && timeString.length === 5 ? timeString : "00:00"}`;
  const format = "dd.MM.yyyy HH:mm";

  if (isPast(stringToDate(dateTime, format))) {
    return;
  }

  return distanceToNow(dateTime, format, addSuffix);
};
