import { api } from "./api";
import { Property, PropertyListWrapper } from "types/models/user-config";

// API доступов пользователя
const prefix = `/user-config-handler/client-area/v1`;
export const userConfigApiUrl = {
  property: `${prefix}/property/all`
};

export const userConfigApi = api.injectEndpoints({
  endpoints: (build) => ({
    userConfigProperty: build.query<Array<Property>, void>({
      query: () => ({
        url: userConfigApiUrl.property
      }),
      transformResponse: (response: PropertyListWrapper, meta, arg) => response?.data
    })
  })
});

export const { useLazyUserConfigPropertyQuery } = userConfigApi;
