import { phoneClear } from "./helpers";
import { getMessageCount } from "entities/campaign/lib";
import { translation } from "shared/ui/translation/translation";
import { boolean, number, string } from "yup";

const VALIDATION_TEXT_MESSAGE = {
  max: (params: { max: number }) => translation("Максимальное кол-во символов") + " " + params.max,
  required: translation("Обязательное поле")
};

const validationTime = (data: string): boolean => {
  return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(data);
};

const validationPhoneList = ({
  value,
  maxLength = 10000,
  checkLength
}: {
  value: string;
  maxLength?: number;
  checkLength?: boolean;
}): boolean => {
  const arr = value?.replace(/\r\n/g, "\n")?.split("\n");
  // Добавились иностранные номера
  const validPhones = arr?.filter((val) => {
    const clear = phoneClear(val)?.length;
    return checkLength ? clear === 11 : clear;
  });

  return !!validPhones?.length && arr?.length <= maxLength;
};

const validationTrim = (value: string): boolean => {
  return value.trim()?.length > 0;
};

const validationMessage = (value: string, checkMax = true): boolean => {
  let maxValid = true;

  if (checkMax) {
    const maxCount = 255;
    const count = getMessageCount(value);
    maxValid = count <= maxCount;
  }

  return maxValid && validationTrim(value);
};

const validationSearch = (value: string | undefined, min = 0, trim = true): boolean => {
  if (!value?.length) {
    return true;
  }

  let val = value;

  if (trim) {
    val = val.trim();
  }

  return val?.length >= min;
};

export {
  VALIDATION_TEXT_MESSAGE,
  validationTime,
  validationPhoneList,
  validationMessage,
  validationTrim,
  validationSearch
};
