import { ScenarioItemType } from "types/enums/campaign";
import { Sender } from "types/models/sender-pattern/sender";
import {
  Carrier,
  ClientRegistrationSender,
  ClientRegistrationSenderState,
  LegalEntity,
  Pattern,
  PatternState,
  PatternStateEnum,
  SenderState
} from "types/models/sender-pattern";

export const getSenderMock = (data: Partial<Sender> = {}): Sender => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  channel: ScenarioItemType.SMS,
  sender: "string",
  type: {
    code: "MULTINAMING",
    desc: "Мультиподпись"
  },
  state: SenderState.ACTIVE,
  description: "Отклонили потому что были поданные не верные документы",
  createdAt: "2022-06-16T06:43:21.545Z",
  updatedAt: "2022-06-16T06:43:21.545Z",
  legalEntity: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    inn: "72757357844",
    name: "string"
  },
  carrier: {
    id: "123",
    name: "string"
  },
  ...data
});

export const getRegistrationMock = (data: Partial<ClientRegistrationSender> = {}): ClientRegistrationSender => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  state: ClientRegistrationSenderState.ACTIVE,
  sender: getSenderMock({ ...data?.sender }),
  client: {
    id: "123",
    name: "Клиент"
  },
  description: "Отклонили потому что были поданные не верные документы",
  createdAt: "2022-06-16T06:43:21.545Z",
  updatedAt: "2022-06-16T06:43:21.545Z",
  activatedAt: "2022-06-16T06:43:21.545Z",
  deletedAt: "2022-06-16T06:43:21.545Z",
  version: 1,
  ...data
});

export const getCarrierMock = (data: Partial<Carrier> = {}): Carrier => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "Оператор 1",
  ...data
});

export const getSmsPatternMock = (data: Partial<Pattern> = {}): Pattern => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  sender: getSenderMock(),
  legalEntity: getLegalEntityMock(),
  carrier: getCarrierMock(),
  trafficType: {
    id: "string",
    name: "string"
  },
  pattern: "string",
  state: getSmsPatternStateMock(),
  createdAt: "2024-05-20T06:11:48.930Z",
  updatedAt: "2024-05-20T06:11:48.930Z",
  deletedAt: "2024-05-20",
  activatedAt: "2024-05-20",
  ...data
});

export const getSmsPatternStateMock = (data: Partial<PatternState> = {}): PatternState => ({
  name: PatternStateEnum.APPROVAL,
  desc: "APPROVAL",
  ...data
});

export const getLegalEntityMock = (data: Partial<LegalEntity> = {}): LegalEntity => ({
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  inn: "string",
  name: "Оператор 1",
  ...data
});

export default {
  senderPatternSenders: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa1" }),
        getSenderMock({
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa2",
          channel: ScenarioItemType.PUSH,
          state: SenderState.DISABLED
        }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa3", channel: ScenarioItemType.PUSH }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa4", channel: ScenarioItemType.VIBER }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa5" }),
        getSenderMock({
          id: "3fa85f64-5717-4562-b3fc-2c963f66af16",
          channel: ScenarioItemType.PUSH,
          state: SenderState.DISABLED
        }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa7", channel: ScenarioItemType.WHATSAPP }),
        getSenderMock({
          id: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
          channel: ScenarioItemType.PUSH,
          state: SenderState.DISABLED
        }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa9" }),
        getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa0" })
      ].map((sender) => getRegistrationMock({ id: sender.id, sender }))
    }
  },
  senderPatternSender: {
    data: getRegistrationMock({
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa1",
      sender: getSenderMock({ id: "3fa85f64-5717-4562-b3fc-2c963f66afa1" })
    })
  },
  senderPatternCarriers: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getCarrierMock({ id: "1", name: "Оператор 1" }),
        getCarrierMock({ id: "2", name: "Оператор 2" }),
        getCarrierMock({ id: "3", name: "Оператор 3" }),
        getCarrierMock({ id: "4", name: "Оператор 4" })
      ]
    }
  },
  senderPatternLegalEntities: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getLegalEntityMock({ id: "1", name: "Юр. лицо 1" }),
        getLegalEntityMock({ id: "2", name: "Юр. лицо 2" }),
        getLegalEntityMock({ id: "3", name: "Юр. лицо 3" }),
        getLegalEntityMock({ id: "4", name: "Юр. лицо 4" })
      ]
    }
  },
  senderPatternSmsPattens: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getSmsPatternMock({ id: "1", pattern: "pattern 1" }),
        getSmsPatternMock({ id: "2", pattern: "pattern 2" }),
        getSmsPatternMock({ id: "3", pattern: "pattern 3" }),
        getSmsPatternMock({ id: "4", pattern: "pattern 4" }),
        getSmsPatternMock({ id: "5", pattern: "pattern 5" }),
        getSmsPatternMock({ id: "6", pattern: "pattern 6" }),
        getSmsPatternMock({ id: "7", pattern: "pattern 7" }),
        getSmsPatternMock({ id: "8", pattern: "pattern 8" }),
        getSmsPatternMock({ id: "9", pattern: "pattern 9" }),
        getSmsPatternMock({ id: "10", pattern: "pattern 10" })
      ]
    }
  },
  senderPatternSmsPattenStates: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [getSmsPatternStateMock(), getSmsPatternStateMock(), getSmsPatternStateMock(), getSmsPatternStateMock()]
    }
  }
};
