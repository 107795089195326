import React, { PropsWithChildren } from "react";
import styles from "./Header.module.scss";
import Logo from "../logo/Logo";

import MainMenu from "./main-menu/MainMenu";
import User from "../../features/user/User";

interface OwnProps {}

type Props = OwnProps;

const Header = (props: PropsWithChildren<Props>) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerTop}>
        <div className={styles.logo}>
          <Logo id="header-logo" />
        </div>
        <div className={styles.user}>
          <User />
        </div>
      </div>
      <nav>
        <MainMenu />
      </nav>
    </div>
  );
};

export default Header;
