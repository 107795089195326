import { api } from "./api";
import { PricelistStartedAtWrapper } from "types/models/pricelist";

const prefix = `/crm-pricelist-manager/hub/v1`;
export const priceListApiUrl = {
  startedDate: `${prefix}/started-at-first-actual-pricelist`
};

export const priceListApi = api.injectEndpoints({
  endpoints: (build) => ({
    priceListStartedDate: build.query<string | undefined | null, void>({
      query: () => ({
        url: priceListApiUrl.startedDate
      }),
      transformResponse: (response: PricelistStartedAtWrapper, meta, arg) => response.data?.date
    })
  })
});

export const { useLazyPriceListStartedDateQuery } = priceListApi;
