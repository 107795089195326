import React, { PropsWithChildren } from "react";
import { ClassValue, clsx } from "clsx";
import { Typography } from "types/interfaces/typography";
import { getTypographyClassNames } from "shared/lib/classNames";

interface OwnProps extends Partial<Typography> {
  className?: ClassValue;
  level?: number;
  caption?: boolean;
  id?: string;
}

type Props = OwnProps;

const Title = (props: PropsWithChildren<Props>) => {
  const { className, children, level = 1, caption = false, id, ...typography } = props;

  const Component: any = caption ? "div" : `h${level}`;

  return (
    <Component
      className={clsx(className, `h${level}`, `e2e-title`, `e2e-title-${level}`, getTypographyClassNames(typography))}
      id={id}
    >
      {children}
    </Component>
  );
};

export default Title;
