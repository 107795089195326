import React from "react";
import Fallback from "shared/ui/fallback/Fallback";

const SimpleMessageBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'simple-message' */
      /* webpackPrefetch: 10 */
      "./SimpleMessageRouter"
    )
);

const SimpleMessageModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <SimpleMessageBundle />
  </React.Suspense>
);

export default SimpleMessageModule;
