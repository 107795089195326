import React, { PropsWithChildren } from "react";
import Fallback from "shared/ui/fallback/Fallback";

const CampaignsBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'campaigns' */
      /* webpackPrefetch: 10 */
      "./CampaignsRouter"
    )
);

const CampaignsModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <CampaignsBundle />
  </React.Suspense>
);

export default CampaignsModule;
