import { CampaignState, CampaignType, ScenarioItemType } from "types/enums/campaign";
import { CampaignStatistic, SourceState } from "types/models/campaign";
import { CampaignForm } from "types/interfaces/campaign";
import { Color } from "types/interfaces/styles";

export const CAMPAIGN_STATUSES: { [key in CampaignState]: { title: string; view?: boolean; finished?: boolean } } = {
  [CampaignState.NEW]: { title: "Создается", view: false, finished: false },
  [CampaignState.DRAFT]: { title: "Черновик", view: false, finished: false },
  [CampaignState.ACTIVATING]: { title: "Создается", view: true, finished: false },
  [CampaignState.ENRICH_TIMEZONE]: { title: "Создается", view: true, finished: false },
  [CampaignState.REPLACE_SHORT_LINK]: { title: "Создается", view: true, finished: false },
  [CampaignState.CREATED]: { title: "Создается", view: true, finished: false },
  [CampaignState.CREATING]: { title: "Создается", view: true, finished: false },
  [CampaignState.PAUSED]: { title: "Приостановлена", view: true, finished: false },
  [CampaignState.ACTIVE]: { title: "Выполняется", view: true, finished: false },
  [CampaignState.COMPLETE]: { title: "Выполнена", view: true, finished: false },
  [CampaignState.FINISHED]: { title: "Завершена", view: true, finished: true },
  [CampaignState.FAILED]: { title: "Приостановлена из-за ошибки", view: true, finished: true },
  [CampaignState.DELETED]: { title: "Удалена", view: false, finished: true }
};

export const CAMPAIGNS_STATUS_FILTER = [
  { title: "Черновик", id: `${CampaignState.NEW},${CampaignState.DRAFT}` },
  {
    title: "Создается",
    id: `${CampaignState.ACTIVATING},${CampaignState.CREATED},${CampaignState.CREATING},${CampaignState.ENRICH_TIMEZONE},${CampaignState.REPLACE_SHORT_LINK}`
  },
  { title: "Выполняется", id: CampaignState.ACTIVE },
  { title: "Приостановлена", id: CampaignState.PAUSED },
  { title: "Отменена", id: CampaignState.DELETED },
  { title: "Выполнена", id: CampaignState.COMPLETE },
  { title: "Завершена", id: CampaignState.FINISHED },
  { title: "С ошибками", id: CampaignState.FAILED }
];

export const CAMPAIGNS_TYPE: { [key in CampaignType]?: { title: string; created: boolean } } = {
  [CampaignType.SIMPLE]: { title: "Обычная", created: true },
  [CampaignType.INDIVIDUAL]: { title: "Индивидуальная", created: true },
  [CampaignType.INDIVIDUAL_FROM_FILE]: { title: "Из файла", created: false },
  [CampaignType.TAGS]: { title: "С подстановками", created: true }
};

export const SCENARIO_TYPE: { [key in ScenarioItemType]?: string } = {
  [ScenarioItemType.VIBER]: "Viber",
  [ScenarioItemType.PUSH]: "PUSH",
  [ScenarioItemType.VKOK]: "Vk/Ok",
  [ScenarioItemType.VK]: "Vk/Ok",
  [ScenarioItemType.OK]: "Vk/Ok",
  [ScenarioItemType.WHATSAPP]: "WhatsApp",
  [ScenarioItemType.SMS]: "SMS"
};

export const SCENARIO_TYPE_VISIBLE: ScenarioItemType[] = [
  ScenarioItemType.VIBER,
  ScenarioItemType.PUSH,
  ScenarioItemType.VKOK,
  ScenarioItemType.WHATSAPP,
  ScenarioItemType.SMS
];

export const SCENARIO_TYPE_COLOR: { [key in ScenarioItemType]?: Color } = {
  [ScenarioItemType.VIBER]: "purple8",
  [ScenarioItemType.PUSH]: "black",
  [ScenarioItemType.VKOK]: "blue10",
  [ScenarioItemType.VK]: "blue10",
  [ScenarioItemType.OK]: "blue10",
  [ScenarioItemType.WHATSAPP]: "green4",
  [ScenarioItemType.SMS]: "black"
};

export const SOURCE_STATES = {
  [SourceState.CREATING]: { title: "Обрабатывается" },
  [SourceState.CREATED]: { title: "Ок" },
  [SourceState.ERROR]: { title: "Ошибка" },
  [SourceState.CREATED_WITH_ERROR]: { title: "Требуется действие" },
  [SourceState.DELETED]: { title: "Отменен" }
};

export const CAMPAIGN_FORMS: CampaignForm[] = [
  {
    id: "name",
    name: "Название",
    title: "Название"
  },
  {
    id: "date",
    name: "Дата старта и завершения",
    title: "Дата старта и завершения"
  },
  {
    id: "shortLink",
    name: "Сокращение ссылок",
    title: "Использовать сокращение ссылок в тексте"
  },
  {
    id: "message",
    name: "Базовый текст сообщения",
    title: "Базовый текст сообщения"
  },
  {
    id: "channel",
    name: "Каналы",
    title: "Каналы"
  },
  {
    id: "recipient",
    name: "Получатели",
    title: "Получатели"
  },
  {
    id: "speed",
    name: "Скорость рассылки",
    title: "Скорость рассылки"
  },
  {
    id: "common",
    name: "Общая готовность",
    title: "Общая готовность"
  }
];

export const CAMPAIGN_STATISTIC: { [key in keyof CampaignStatistic]: string } = {
  inQueue: "Ожидание отправки по расписанию",
  sent: "Отправлено",
  delivered: "Доставлено",
  notDelivered: "Не доставлено",
  seen: "Просмотрено"
};
