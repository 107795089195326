import { UiError, UiErrorResponse } from "types/interfaces/error";

export const getErrorMock = (data: Partial<UiError> = {}): UiErrorResponse => ({
  error: {
    id: "123e4567-e89b-12d3-a456-4266554400012",
    code: "validation_error",
    message: "",
    status: 500,
    errors: [],
    ...data
  }
});

export default {
  errorExport: getErrorMock({
    code: "excel_limit_exceeded_export_error",
    message: "Download data limit exceeded",
    status: 400
  }),
  errorExcel: getErrorMock({
    code: "export_excel_row_limit_exceeded_error",
    message: "Download data limit exceeded",
    status: 400
  })
};
