import React, { PropsWithChildren } from "react";
import Navbar from "shared/ui/navbar/Navbar";
import NavbarItem from "shared/ui/navbar/NavbarItem";
import Box from "shared/ui/typography/Box";
import Icon from "shared/ui/icon/Icon";
import { useAppDispatch, useAppSelector } from "app/hooks/useStore";
import { authActions, selectAuth, selectClientId } from "entities/auth/model";
import { selectUser, selectUserLoaded } from "entities/user/model";
import { useLogoutMutation } from "shared/api/auth";

interface OwnProps {}

type Props = OwnProps;

const User = (props: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const loaded = useAppSelector(selectUserLoaded);
  const clientId = useAppSelector(selectClientId);
  const [logout, { isLoading: logoutLoading }] = useLogoutMutation();

  const sendLogout = () => {
    logout({ clientId, refreshToken: auth?.refresh_token || "" })
      .unwrap()
      .finally(() => {
        dispatch(authActions.clearAuth());
      });
  };

  return (
    <Box>
      <Navbar right>
        <NavbarItem type="link" href="https://docs.hub.megafon.ru" target="_blank" id="user-docs" icon>
          <Icon name="question-circle" size={20} />
          <Box color="text" pl={12}>
            Справка
          </Box>
        </NavbarItem>
        <NavbarItem type="router" to="/user" id="user-profile" icon>
          <Icon name="user" size={20} />
          <Box maxWidth={300} color="text" className="ellipsis" pl={12}>
            {user?.name}
          </Box>
        </NavbarItem>
        <NavbarItem type="link" onClick={() => !logoutLoading && sendLogout()} id="user-logout" icon>
          <Icon name="logout" size={20} />
        </NavbarItem>
      </Navbar>
    </Box>
  );
};

export default User;
