import { Body, Credentials, GrantType } from "types/models/auth";

const prefix = `/auth/openid-connect`;
export const authApiUrl = {
  token: `${prefix}/token`,
  logout: `${prefix}/logout`
};

export const getAuthQueryToken = (data: Credentials) => {
  const params = new URLSearchParams();
  params.append("client_id", data.clientId);
  params.append("grant_type", data.grantType);
  if (data.grantType === GrantType.Password) {
    params.append("username", data?.username || "");
    params.append("password", data?.password || "");
  } else {
    params.append("refresh_token", data?.refreshToken || "");
  }

  return {
    url: authApiUrl.token,
    method: "POST",
    body: params
  };
};
export const getAuthQueryLogout = (data: Body) => {
  const params = new URLSearchParams();
  params.append("client_id", data.clientId);
  params.append("refresh_token", data.refreshToken);

  return {
    url: authApiUrl.logout,
    method: "POST",
    body: params
  };
};
