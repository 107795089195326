import { api } from "./api";
import { FileUrl, FileUrlWrapper, Source, SourceWrapper } from "types/models/campaign";

const prefix = "/campaign/client-area/v1";
export const exportApiUrl = {
  exportSource: (sourceId: string) => `${prefix}/export/source/${sourceId}/recipients/download-url`,
  exportSourceGenerate: (sourceId: string) => `${prefix}/export/source/${sourceId}/recipients/generate-file`,
  exportErrorSource: (sourceId: string) => `${prefix}/export/source/${sourceId}/error-recipients/download-url`,
  exportErrorSourceGenerate: (sourceId: string) => `${prefix}/export/source/${sourceId}/error-recipients/generate-file`
};

export const exportApi = api.injectEndpoints({
  endpoints: (build) => ({
    exportSourceGenerate: build.mutation<Source, { sourceId: string; campaignId?: string }>({
      query: ({ sourceId }) => ({
        url: exportApiUrl.exportSourceGenerate(sourceId)
      }),
      transformResponse: (response: SourceWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [{ type: "Campaign", id: _req?.campaignId }]
    }),
    exportSource: build.query<FileUrl, string>({
      query: (sourceId) => ({
        url: exportApiUrl.exportSource(sourceId)
      }),
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data
    }),
    exportErrorSourceGenerate: build.mutation<Source, { sourceId: string; campaignId?: string }>({
      query: ({ sourceId }) => ({
        url: exportApiUrl.exportErrorSourceGenerate(sourceId)
      }),
      transformResponse: (response: SourceWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [{ type: "Campaign", id: _req?.campaignId }]
    }),
    exportErrorSource: build.query<FileUrl, string>({
      query: (sourceId) => ({
        url: exportApiUrl.exportErrorSource(sourceId)
      }),
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data
    })
  })
});

export const {
  useLazyExportSourceQuery,
  useExportSourceGenerateMutation,
  useLazyExportErrorSourceQuery,
  useExportErrorSourceGenerateMutation
} = exportApi;
