import React, { PropsWithChildren } from "react";
import logo from "app/assets/logo.svg";
import logoMin from "app/assets/logo-min.svg";
import styles from "./Logo.module.scss";
import { NavLink } from "react-router-dom";
import Title from "shared/ui/typography/Title";
import Row from "shared/ui/grid/Row";
import Col from "shared/ui/grid/Col";

interface OwnProps {
  collapsed?: boolean;
  id?: string;
}

export type Props = OwnProps;

const Logo = (props: PropsWithChildren<Props>) => {
  const { id, collapsed = false } = props;

  return (
    <NavLink to="/" id={id}>
      <Row gutter={30} align="middle">
        <Col>
          <div className={styles.logo}>
            <img className={styles.img} src={collapsed ? logoMin : logo} alt="Logo" />
          </div>
        </Col>
        <Col>
          <div className={styles.logoDiver} />
        </Col>
        <Col>
          <Title level={3} caption color="green">
            Хаб
          </Title>
        </Col>
      </Row>
    </NavLink>
  );
};

export default Logo;
