import { api } from "./api";
import { CheckPatternRequest, CheckPatternResponse, CheckPatternWrapper } from "types/models/sender-pattern-check";

// API проверки SMS шаблонов
const prefix = `/sender-pattern/client-area/v1`;
export const senderPatternCheckApiUrl = {
  check: `${prefix}/check-pattern`
};

export const senderPatternCheckApi = api.injectEndpoints({
  endpoints: (build) => ({
    senderPatternCheck: build.query<CheckPatternResponse, CheckPatternRequest>({
      query: (data) => ({
        url: senderPatternCheckApiUrl.check,
        method: "POST",
        body: data
      }),
      transformResponse: (response: CheckPatternWrapper, meta, arg) => response?.data
    })
  })
});

export const { useLazySenderPatternCheckQuery } = senderPatternCheckApi;
