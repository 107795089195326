import React, { PropsWithChildren } from "react";
import { ClassValue, clsx } from "clsx";
import styles from "./Span.module.scss";
import { Typography } from "types/interfaces/typography";
import { getTypographyClassNames } from "shared/lib/classNames";
import { Size } from "types/interfaces/styles";

interface OwnProps extends Partial<Typography> {
  className?: ClassValue;
  size?: Size;
  id?: string;
}

type Props = OwnProps;

const Span = (props: PropsWithChildren<Props>) => {
  const { className, id, children, size = "md", ...typography } = props;

  return (
    <span
      className={clsx(className, getTypographyClassNames(typography), {
        [styles[size]]: size
      })}
      id={id}
    >
      {children}
    </span>
  );
};

export default Span;
