import React, { PropsWithChildren } from "react";
import Fallback from "shared/ui/fallback/Fallback";

const NamesBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'names' */
      /* webpackPrefetch: 10 */
      "./NamesRouter"
    )
);

const NamesModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <NamesBundle />
  </React.Suspense>
);

export default NamesModule;
