import { ReactComponent as Addresses } from "app/assets/icons/addresses.svg";
import { ReactComponent as ArrowBottom } from "app/assets/icons/arrow-bottom.svg";
import { ReactComponent as ArrowLeft } from "app/assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "app/assets/icons/arrow-right.svg";
import { ReactComponent as ArrowTop } from "app/assets/icons/arrow-top.svg";
import { ReactComponent as Campaign } from "app/assets/icons/campaign.svg";
import { ReactComponent as Check } from "app/assets/icons/check.svg";
import { ReactComponent as CheckOutline } from "app/assets/icons/check-outline.svg";
import { ReactComponent as CheckCircle } from "app/assets/icons/check-circle.svg";
import { ReactComponent as CheckThin } from "app/assets/icons/check-thin.svg";
import { ReactComponent as Clear } from "app/assets/icons/clear.svg";
import { ReactComponent as Cloud } from "app/assets/icons/cloud.svg";
import { ReactComponent as CloudDownload } from "app/assets/icons/cloud-download.svg";
import { ReactComponent as CloudOn } from "app/assets/icons/cloud-on.svg";
import { ReactComponent as Dashed } from "app/assets/icons/dashed.svg";
import { ReactComponent as Date } from "app/assets/icons/date.svg";
import { ReactComponent as Dotted } from "app/assets/icons/dotted.svg";
import { ReactComponent as Drag } from "app/assets/icons/drag.svg";
import { ReactComponent as Exit } from "app/assets/icons/exit.svg";
import { ReactComponent as Eye } from "app/assets/icons/eye.svg";
import { ReactComponent as EyeClosed } from "app/assets/icons/eye-closed.svg";
import { ReactComponent as File } from "app/assets/icons/file.svg";
import { ReactComponent as Group } from "app/assets/icons/group.svg";
import { ReactComponent as Info } from "app/assets/icons/info.svg";
import { ReactComponent as InfoCircle } from "app/assets/icons/info-circle.svg";
import { ReactComponent as Loader } from "app/assets/icons/loader.svg";
import { ReactComponent as Logout } from "app/assets/icons/logout.svg";
import { ReactComponent as Love } from "app/assets/icons/love.svg";
import { ReactComponent as Mail } from "app/assets/icons/mail.svg";
import { ReactComponent as Man } from "app/assets/icons/man.svg";
import { ReactComponent as ManOk } from "app/assets/icons/man-ok.svg";
import { ReactComponent as Mask } from "app/assets/icons/mask.svg";
import { ReactComponent as Men } from "app/assets/icons/men.svg";
import { ReactComponent as Menu } from "app/assets/icons/menu.svg";
import { ReactComponent as Minus } from "app/assets/icons/minus.svg";
import { ReactComponent as Paragraph } from "app/assets/icons/paragraph.svg";
import { ReactComponent as Pluse } from "app/assets/icons/pluse.svg";
import { ReactComponent as QuestionCircle } from "app/assets/icons/question-circle.svg";
import { ReactComponent as Refresh } from "app/assets/icons/refresh.svg";
import { ReactComponent as Report } from "app/assets/icons/report.svg";
import { ReactComponent as Search } from "app/assets/icons/search.svg";
import { ReactComponent as SelectArrow } from "app/assets/icons/select-arrow.svg";
import { ReactComponent as Setting } from "app/assets/icons/setting.svg";
import { ReactComponent as Spinner } from "app/assets/icons/spinner.svg";
import { ReactComponent as Star } from "app/assets/icons/star.svg";
import { ReactComponent as Time } from "app/assets/icons/time.svg";
import { ReactComponent as Timer } from "app/assets/icons/timer.svg";
import { ReactComponent as Trash } from "app/assets/icons/trash.svg";
import { ReactComponent as Union } from "app/assets/icons/union.svg";
import { ReactComponent as User } from "app/assets/icons/user.svg";
import { ReactComponent as Warning } from "app/assets/icons/warning.svg";
import { ReactComponent as WarningCircle } from "app/assets/icons/warning-circle.svg";
import { ReactComponent as Wave } from "app/assets/icons/wave.svg";
import { ReactComponent as Filter } from "app/assets/icons/filter.svg";
import { ReactComponent as ExitCircle } from "app/assets/icons/exit-circle.svg";
import { ReactComponent as Select } from "app/assets/icons/select.svg";
import { ReactComponent as Upload } from "app/assets/icons/upload.svg";
import { ReactComponent as Edit } from "app/assets/icons/edit.svg";
import React from "react";
import { clsx } from "clsx";
import { Color } from "types/interfaces/styles";
import Span from "../typography/Span";

export type IconType =
  | "addresses"
  | "arrow-bottom"
  | "arrow-left"
  | "arrow-right"
  | "arrow-top"
  | "campaign"
  | "check"
  | "check-outline"
  | "check-circle"
  | "check-thin"
  | "clear"
  | "cloud"
  | "cloud-download"
  | "cloud-on"
  | "dashed"
  | "date"
  | "dotted"
  | "drag"
  | "exit"
  | "eye"
  | "eye-closed"
  | "file"
  | "filter"
  | "group"
  | "info"
  | "info-circle"
  | "loader"
  | "logout"
  | "love"
  | "mail"
  | "man"
  | "man-ok"
  | "mask"
  | "men"
  | "menu"
  | "minus"
  | "paragraph"
  | "pluse"
  | "question-circle"
  | "refresh"
  | "report"
  | "search"
  | "select-arrow"
  | "setting"
  | "spinner"
  | "star"
  | "time"
  | "timer"
  | "trash"
  | "union"
  | "user"
  | "warning"
  | "warning-circle"
  | "exit-circle"
  | "select"
  | "upload"
  | "edit"
  | "wave";

const ICONS: { [key: string]: any } = {
  "user": User,
  "report": Report,
  "mask": Mask,
  "campaign": Campaign,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-bottom": ArrowBottom,
  "arrow-top": ArrowTop,
  "addresses": Addresses,
  "pluse": Pluse,
  "question-circle": QuestionCircle,
  "dotted": Dotted,
  "star": Star,
  "info": Info,
  "info-circle": InfoCircle,
  "check": Check,
  "check-thin": CheckThin,
  "check-circle": CheckCircle,
  "file": File,
  "filter": Filter,
  "exit": Exit,
  "minus": Minus,
  "search": Search,
  "trash": Trash,
  "mail": Mail,
  "setting": Setting,
  "select-arrow": SelectArrow,
  "logout": Logout,
  "date": Date,
  "time": Time,
  "group": Group,
  "warning": Warning,
  "warning-circle": WarningCircle,
  "refresh": Refresh,
  "love": Love,
  "paragraph": Paragraph,
  "spinner": Spinner,
  "cloud": Cloud,
  "dashed": Dashed,
  "drag": Drag,
  "timer": Timer,
  "loader": Loader,
  "cloud-on": CloudOn,
  "wave": Wave,
  "man": Man,
  "men": Men,
  "man-ok": ManOk,
  "menu": Menu,
  "eye": Eye,
  "eye-closed": EyeClosed,
  "check-outline": CheckOutline,
  "union": Union,
  "clear": Clear,
  "cloud-download": CloudDownload,
  "exit-circle": ExitCircle,
  "select": Select,
  "upload": Upload,
  "edit": Edit
};

interface OwnProps {
  name: IconType;
  color?: Color;
  size?: number;
  spin?: boolean;
  flash?: boolean;
  slow?: boolean;
  block?: boolean;
}

type Props = OwnProps;

const Icon: React.FC<Props> = ({
  name,
  color,
  size = 16,
  spin = false,
  flash = false,
  slow = false,
  block = false
}) => {
  const params = {
    fill: "currentColor",
    width: size,
    height: size
  };

  if (ICONS[name]) {
    const Component = ICONS[name];

    return (
      <Span color={color} className={clsx({ "d-inline-flex": !block, "d-block": block })}>
        <Component
          {...params}
          className={clsx({ "spin": spin && !slow, "spin_slow": spin && slow, "flash": flash, "d-block": block })}
        />
      </Span>
    );
  } else {
    return null;
  }
};

export default Icon;
