import React from "react";
import Fallback from "shared/ui/fallback/Fallback";

const SmsPatternCheckBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'sms-pattern-check' */
      /* webpackPrefetch: 10 */
      "./SmsPatternCheckRouter"
    )
);

const SmsPatternCheckModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <SmsPatternCheckBundle />
  </React.Suspense>
);

export default SmsPatternCheckModule;
