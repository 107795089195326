import { ScenarioItemType } from "types/enums/campaign";

export default {
  channels: {
    data: {
      channels: [
        ScenarioItemType.SMS,
        ScenarioItemType.VIBER,
        ScenarioItemType.VK,
        ScenarioItemType.WHATSAPP,
        ScenarioItemType.PUSH
      ]
    }
  },
  senders: { data: { senders: ["Megafon", "Megafon2", "Megafon3"] } }
};
