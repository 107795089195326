export default {
  exportSourceRecipients: {
    data: {
      url: "https://url.com"
    }
  },
  exportSourceErrorRecipients: {
    data: {
      url: "https://url.com"
    }
  }
};
