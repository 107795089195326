import { ERROR_CODE } from "shared/config/error";
import { INPUT_ERRORS } from "shared/config/inputErrors";
import { translation } from "shared/ui/translation/translation";

export const prepareInputError = ({
  key,
  values
}: {
  key: keyof typeof INPUT_ERRORS;
  values?: { [key: string]: string | number };
}) => {
  let result: string = INPUT_ERRORS[key];
  result = translation(result);

  if (values) {
    Object.entries(values).forEach(([param, value]) => {
      result = result.replaceAll(`{{${param}}}`, value.toString());
    });
  }

  return result;
};

export const getErrorByCode = (code?: string | null): null | string => {
  if (!code) {
    return null;
  }

  return (ERROR_CODE as Record<string, any>)?.[code] || null;
};
