import { notification, notificationDanger, notificationWarning } from "shared/ui/notification/Notification";
import { ERROR_STATUS } from "shared/config/error";
import { ErrorResponse, UiErrorResponse } from "types/interfaces/error";
import { getErrorByCode } from "./error";
import { ReactNode } from "react";

const errorHandler = (
  error?: ErrorResponse<UiErrorResponse> | null,
  message?: string,
  type: "error" | "warning" = "error",
  messagesByCode?: { [key: number]: string }
) => {
  if (!error) {
    if (message) {
      // Ручная ошибка без бэка
      notification({ message: message, type });
    }
    return;
  }

  const apiError: ErrorResponse<UiErrorResponse> | undefined = error;
  const status = apiError?.status;
  const statusText = apiError?.statusText;
  const errorMessage = apiError?.data?.error?.message;
  const errorCode = apiError?.data?.error?.code;
  const errorMulti = apiError?.data?.error?.errors;

  if (status && messagesByCode?.[status]) {
    // Ручноя ошибка по статусу
    notification({ message: messagesByCode?.[status], type });
  } else if (errorMessage || errorCode || errorMulti?.length) {
    // Ошибка с бэка
    let dataMessage =
      getErrorByCode(errorCode) || errorMessage || message || (errorMulti?.length ? "Ошибки" : "Ошибка");
    let description: ReactNode;

    if (errorMulti?.length) {
      description = (
        <ul>
          {errorMulti.map((item, i) => (
            <li key={i}>{item?.message}</li>
          ))}
        </ul>
      );
    }

    notificationDanger(dataMessage, description, errorCode || "" + status);
  } else if (status) {
    // Ошибка запроса по статусу
    const statusMessage = message || statusText;

    switch (status) {
      case 401:
        notificationWarning(ERROR_STATUS[401], statusMessage, "401");
        break;
      case 400:
      case 403:
        notificationDanger(ERROR_STATUS[403], statusMessage, "403");
        break;
      case 413:
        notificationDanger(ERROR_STATUS[413], undefined, "413");
        break;
      default:
        notification({ message: statusMessage, type });
    }
  } else if (statusText === "Network Error") {
    // Отсутствие соединение
    notificationWarning(ERROR_STATUS["0"], undefined, "Network Error");
  }
};

export default errorHandler;
