export enum ReportType {
  CAMPAIGN = "hub-campaign-report",
  TRAFFIC = "hub-traffic-report",
  SUMMARY = "hub-summary-report",
  FINANCIAL = "report-financial", // Финансовая справка
  SHORT_URL = "short-url-clicks-report" // Отчет о переходах по коротким ссылкам
}

export enum SourceTraffics {
  HTTP = "HTTP",
  SMPP = "SMPP",
  WEB = "WEB",
  EMAIL = "EMAIL",
  WEB_SM = "WEB_SM"
}

export enum ReportStep {
  Create = "create",
  View = "view"
}

export enum MessageState {
  SENT = "SENT",
  FAILED = "FAILED",
  DELIVERED = "DELIVERED",
  NOT_DELIVERED = "NOT_DELIVERED",
  PENDING = "PENDING",
  SEEN = "SEEN",
  // Не используются
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  SCHEDULED = "SCHEDULED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
  UNKNOWN = "UNKNOWN"
}
