import { rest } from "msw";
import { MOCK_API_DELAY, MOCK_API_URL } from "./config";
import { mwsBlob, mwsError, mwsSuccess } from "./helpers";
import mocks from "shared/mocks";
import environment from "shared/config/environment";
import { getCredentialMock } from "shared/mocks/user";
import { RoleName } from "types/enums/roleName";
import {
  Campaign,
  ChangeCampaignStateRequest,
  CreateCampaignRequest,
  CreateCampaignTimeConfigRequest
} from "types/models/campaign";
import { CampaignType } from "types/enums/campaign";
import { getCampaignMock } from "shared/mocks/campaigns";
import { CreateReportRequest, Report, ReportState } from "types/models/reports";
import { ReportType } from "types/enums/report";
import { getReportMock } from "shared/mocks/reports";
import { userApiUrl } from "../user";
import { simpleMessageApiUrl } from "../simpleMessage";
import { fileApiUrl } from "../file";
import { crmApiUrl } from "../crm";
import { priceListApiUrl } from "../pricelist";
import { exportApiUrl } from "../export";
import { reportApiUrl } from "../report";
import { auditApiUrl } from "../audit";
import { messageLookupApiUrl } from "../messageLookup";
import { senderPatternApiUrl } from "../senderPattern";
import { presetApiUrl } from "../preset";
import { listApiUrl } from "../list";
import { campaignApiUrl } from "../campaign";
import { userConfigApiUrl } from "../userConfig";
import { UserConfigProperty } from "types/interfaces/userConfig";
import { authApiUrl } from "../helpers/auth";
import { userAccessApiUrl } from "../userAccess";
import { clientApiUrl } from "../client";
import { getClientCredentialMock, getClientDepartmentMock, getClientUserMock } from "../../mocks/client";
import { senderPatternCheckApiUrl } from "../senderPatternCheck";

const user = [
  rest.get(
    MOCK_API_URL + userApiUrl.me,
    mwsSuccess({
      data: getCredentialMock({
        roles: [
          RoleName.TenantAdmin,
          RoleName.MessageLookup,
          RoleName.SimpleMessageSending,
          RoleName.ClientEditor,
          RoleName.ClientViewer,
          RoleName.DepartmentViewer,
          RoleName.DepartmentEditor,
          RoleName.SenderPatternViewer,
          RoleName.SmsPatternViewer
        ]
      })
    })
    // mwsError()
  ),
  rest.get(MOCK_API_URL + userApiUrl.client(":id"), mwsSuccess(mocks.client)),
  rest.get(MOCK_API_URL + userApiUrl.department(":id"), mwsSuccess(mocks.department)),
  rest.post(MOCK_API_URL + userApiUrl.clients, mwsSuccess(mocks.clients)),
  rest.post(MOCK_API_URL + userApiUrl.departments, mwsSuccess(mocks.departments)),
  rest.post(MOCK_API_URL + userApiUrl.credentials, mwsSuccess(mocks.credentials))
];

const userConfig = [
  rest.get(
    MOCK_API_URL + userConfigApiUrl.property,
    mwsSuccess({
      data: [
        {
          code: UserConfigProperty.reportEnabledTypes,
          value: `${ReportType.CAMPAIGN},${ReportType.TRAFFIC},${ReportType.SUMMARY},${ReportType.FINANCIAL},${ReportType.SHORT_URL}`
        }
      ]
    })
  )
];

// USER ACCESS
const userAccess = [rest.get(MOCK_API_URL + userAccessApiUrl.accessProfiles, mwsSuccess(mocks.accessProfiles))];

// CLIENT
const client = [
  // CREDENTIAL
  rest.post(MOCK_API_URL + clientApiUrl.credentials, mwsSuccess(mocks.clientCredentials)),
  rest.post(MOCK_API_URL + clientApiUrl.credentialCreate, mwsSuccess(mocks.clientCredential)),
  rest.post(
    MOCK_API_URL + clientApiUrl.credentialResetPassword(":id"),
    mwsSuccess({ data: { login: "loginMy", password: "string" } })
  ),
  rest.get(MOCK_API_URL + clientApiUrl.credentialGet(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientCredentialMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  ),
  rest.put(MOCK_API_URL + clientApiUrl.credentialUpdate(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientCredentialMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  ),
  // DEPARTMENT
  rest.post(MOCK_API_URL + clientApiUrl.departments, mwsSuccess(mocks.clientDepartments)),
  rest.post(MOCK_API_URL + clientApiUrl.departmentCreate, mwsSuccess(mocks.clientDepartment)),
  rest.get(MOCK_API_URL + clientApiUrl.departmentGet(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientDepartmentMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  ),
  rest.put(MOCK_API_URL + clientApiUrl.departmentUpdate(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientDepartmentMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  ),
  // USER
  rest.post(MOCK_API_URL + clientApiUrl.users, mwsSuccess(mocks.clientUsers)),
  rest.post(MOCK_API_URL + clientApiUrl.userCreate, mwsSuccess(mocks.clientUser)),
  rest.get(MOCK_API_URL + clientApiUrl.userGet(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientUserMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  ),
  rest.put(MOCK_API_URL + clientApiUrl.userUpdate(":id"), (req, res, ctx) =>
    mwsSuccess({
      data: getClientUserMock({ id: req.params?.id?.toString() || "" })
    })(req, res, ctx)
  )
];

const auth = [
  rest.post(
    MOCK_API_URL + authApiUrl.token,
    mwsSuccess(mocks.auth)
    // mwsError(404, mocks.authError)
  ),
  rest.post(MOCK_API_URL + authApiUrl.logout, (req, res, ctx) => mwsSuccess([ctx.status(204)])(req, res, ctx))
];

const campaign = [
  rest.post(MOCK_API_URL + campaignApiUrl.list, mwsSuccess(mocks.campaigns)),
  rest.post(MOCK_API_URL + campaignApiUrl.create, async (req, res, ctx) => {
    const { type } = await req.json<CreateCampaignRequest>();
    let mock = mocks.campaign;

    if (type === CampaignType.INDIVIDUAL) {
      mock = mocks.campaignIndividual;
    } else if (type === CampaignType.TAGS) {
      mock = mocks.campaignTags;
    }

    return res(ctx.json(mock), ctx.delay(MOCK_API_DELAY));
  }),
  rest.put(MOCK_API_URL + campaignApiUrl.update, async (req, res, ctx) => {
    const body = await req.json<Campaign>();
    return res(ctx.json(body), ctx.delay(MOCK_API_DELAY));
  }),
  rest.get(MOCK_API_URL + campaignApiUrl.get(":id"), async (req, res, ctx) => {
    const id = req.params?.id?.toString();
    let body = mocks.campaign;
    if (id === "2") {
      body = mocks.campaignIndividual;
    } else if (id === "3") {
      body = mocks.campaignTags;
    }
    return res(ctx.json(body), ctx.delay(MOCK_API_DELAY));
  }),
  rest.delete(MOCK_API_URL + campaignApiUrl.delete(":id"), async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + campaignApiUrl.duplicate(":id"), mwsSuccess(mocks.campaign)),
  rest.put(MOCK_API_URL + campaignApiUrl.changeState(":id"), async (req, res, ctx) => {
    const body = await req.json<ChangeCampaignStateRequest>();
    return res(
      ctx.json({ data: getCampaignMock(req.params?.id?.toString(), { state: body?.state }) }),
      ctx.delay(MOCK_API_DELAY)
    );
  }),
  rest.put(MOCK_API_URL + campaignApiUrl.changeStateSource(":id", ":sourceId"), mwsSuccess(mocks.sourceFile)),
  rest.delete(MOCK_API_URL + campaignApiUrl.deleteSource(":id", ":sourceId"), async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + campaignApiUrl.recipientsSource(":id", ":sourceId"), mwsSuccess(mocks.sourceRecipients)),
  rest.post(
    MOCK_API_URL + campaignApiUrl.recipientsErrorSource(":id", ":sourceId"),
    mwsSuccess(mocks.sourceErrorRecipients)
  ),
  rest.post(MOCK_API_URL + campaignApiUrl.importFileSource(":id"), async (req, res, ctx) => {
    const stop = req.url?.searchParams.get("stop") === "true";
    return res(ctx.json(stop ? mocks.sourceStopFile : mocks.sourceFile), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + campaignApiUrl.importTextSource(":id"), async (req, res, ctx) => {
    const stop = req.url?.searchParams.get("stop") === "true";
    return res(ctx.json(stop ? mocks.sourceStopText : mocks.sourceText), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + campaignApiUrl.importListSource(":id"), async (req, res, ctx) => {
    const stop = req.url?.searchParams.get("stop") === "true";
    return res(ctx.json(stop ? mocks.sourceStopList : mocks.sourceList), ctx.delay(MOCK_API_DELAY));
  }),
  rest.get(MOCK_API_URL + campaignApiUrl.getTextSource(":id", ":sourceId"), mwsSuccess(mocks.sourceFromText)),
  rest.put(MOCK_API_URL + campaignApiUrl.updateTextSource(":id", ":sourceId"), mwsSuccess(mocks.sourceText))
];

const campaignExport = [
  rest.get(MOCK_API_URL + exportApiUrl.exportSource(":id"), mwsSuccess(mocks.exportSourceRecipients)),
  rest.get(MOCK_API_URL + exportApiUrl.exportErrorSource(":id"), mwsSuccess(mocks.exportSourceErrorRecipients)),
  rest.get(MOCK_API_URL + exportApiUrl.exportSourceGenerate(":id"), mwsSuccess(mocks.sourceFile)),
  rest.get(MOCK_API_URL + exportApiUrl.exportErrorSourceGenerate(":id"), mwsSuccess(mocks.sourceFile))
];

const campaignTimeConfig = [
  rest.get(MOCK_API_URL + campaignApiUrl.getTimeConfig, (req, res, ctx) => {
    return res(ctx.json(mocks.campaignTimeConfig), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + campaignApiUrl.updateTimeConfig, async (req, res, ctx) => {
    mocks.campaignTimeConfig.data = await req.json<CreateCampaignTimeConfigRequest>();
    return res(ctx.json(mocks.campaignTimeConfig), ctx.delay(MOCK_API_DELAY));
  })
];

const file = [
  rest.post(MOCK_API_URL + fileApiUrl.upload, mwsSuccess(mocks.fileUpload)),
  rest.delete(MOCK_API_URL + fileApiUrl.delete, async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  })
];

const preset = [
  rest.post(MOCK_API_URL + presetApiUrl.presets, mwsSuccess(mocks.presets)),
  rest.post(MOCK_API_URL + presetApiUrl.presetCreate, mwsSuccess(mocks.preset)),
  rest.get(MOCK_API_URL + presetApiUrl.preset(":id"), mwsSuccess(mocks.preset)),
  rest.put(MOCK_API_URL + presetApiUrl.presetUpdate(":id"), mwsSuccess(mocks.preset)),
  rest.delete(MOCK_API_URL + presetApiUrl.presetDelete(":id"), async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  }),
  rest.put(MOCK_API_URL + presetApiUrl.presetEnable(":id"), mwsSuccess(mocks.preset)),
  rest.put(MOCK_API_URL + presetApiUrl.presetDisable(":id"), mwsSuccess(mocks.presetDisable))
];

const list = [
  rest.post(MOCK_API_URL + listApiUrl.lists, mwsSuccess(mocks.lists)),
  rest.post(MOCK_API_URL + listApiUrl.listCreate, mwsSuccess(mocks.list)),
  rest.get(MOCK_API_URL + listApiUrl.list(":id"), mwsSuccess(mocks.list)),
  rest.delete(MOCK_API_URL + listApiUrl.listDelete(":id"), async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  }),
  rest.post(MOCK_API_URL + listApiUrl.listRecipients(":id"), mwsSuccess(mocks.listRecipients)),
  rest.get(MOCK_API_URL + listApiUrl.listRecipientUrl(":id"), mwsSuccess(mocks.listRecipientUrl)),
  rest.get(MOCK_API_URL + listApiUrl.listErrorUrl(":id"), mwsSuccess(mocks.listErrorUrl)),
  rest.post(MOCK_API_URL + listApiUrl.listIgnoreErrors(":id"), mwsSuccess(mocks.list))
];

// REPORT
let reportMock = getReportMock();
const report = [
  rest.post(MOCK_API_URL + reportApiUrl.reports, mwsSuccess(mocks.reports)),

  rest.post(MOCK_API_URL + reportApiUrl.reportCreate, async (req, res, ctx) => {
    const { type } = await req.json<CreateReportRequest>();
    reportMock = getReportMock({ type });

    return res(ctx.json({ data: reportMock }), ctx.delay(MOCK_API_DELAY));
  }),

  rest.get(MOCK_API_URL + reportApiUrl.report(":id"), async (req, res, ctx) => {
    const id = req.params?.id?.toString();
    const body = { data: { ...reportMock, id } };
    return res(ctx.json(body), ctx.delay(MOCK_API_DELAY));
  }),

  rest.put(MOCK_API_URL + reportApiUrl.reportUpdate(":id"), async (req, res, ctx) => {
    const report = await req.json<Report>();
    return res(ctx.json({ data: { ...report, state: ReportState.DRAFT } }), ctx.delay(MOCK_API_DELAY));
  }),

  rest.delete(MOCK_API_URL + reportApiUrl.reportDelete(":id"), async (req, res, ctx) => {
    return res(ctx.status(204), ctx.delay(MOCK_API_DELAY));
  }),

  rest.post(MOCK_API_URL + reportApiUrl.reportStart(":id"), async (req, res, ctx) => {
    const id = req.params?.id?.toString();
    return res(
      ctx.json({ data: getReportMock({ id, state: ReportState.CREATING, type: ReportType.CAMPAIGN }) }),
      ctx.delay(MOCK_API_DELAY)
    );
  }),

  rest.post(MOCK_API_URL + reportApiUrl.reportCancel(":id"), async (req, res, ctx) => {
    const id = req.params?.id?.toString();
    return res(
      ctx.json({ data: getReportMock({ id, state: ReportState.CANCEL, type: ReportType.CAMPAIGN }) }),
      ctx.delay(MOCK_API_DELAY)
    );
  }),

  rest.post(MOCK_API_URL + reportApiUrl.reportRecreate(":id"), async (req, res, ctx) => {
    const id = req.params?.id?.toString();
    return res(
      ctx.json({ data: getReportMock({ id, state: ReportState.CREATING, type: ReportType.CAMPAIGN }) }),
      ctx.delay(MOCK_API_DELAY)
    );
  }),

  rest.post(MOCK_API_URL + reportApiUrl.reportDownloadUrl(":id"), mwsSuccess(mocks.reportDownloadUrl))
];

const priceList = [rest.get(MOCK_API_URL + priceListApiUrl.startedDate, mwsSuccess(mocks.priceListStartedDate))];

const crm = [
  rest.get(MOCK_API_URL + crmApiUrl.channels, mwsSuccess(mocks.channels)),
  rest.get(MOCK_API_URL + crmApiUrl.senders(":id"), mwsSuccess(mocks.senders))
];

const audit = [
  rest.post(MOCK_API_URL + auditApiUrl.events, mwsSuccess(mocks.events)),
  rest.get(MOCK_API_URL + auditApiUrl.actors, mwsSuccess(mocks.actors)),
  rest.get(MOCK_API_URL + auditApiUrl.section, mwsSuccess(mocks.section)),
  rest.post(MOCK_API_URL + auditApiUrl.eventsDownloadUrl, mwsSuccess(mocks.eventsDownloadUrl))
];

const messageLookup = [
  rest.post(MOCK_API_URL + messageLookupApiUrl.messages, mwsSuccess(mocks.messages)),
  rest.get(MOCK_API_URL + messageLookupApiUrl.countLimit, mwsSuccess(mocks.messagesCountLimit)),
  rest.post(
    MOCK_API_URL + messageLookupApiUrl.download,
    mwsBlob(environment.BASENAME + "/fixtures/activity_log.xlsx", "Activity_log_from_23.05.2023 09_33_27.xlsx")
    // mwsError(400, mocks.errorExcel)
  )
];

const senderPattern = [
  rest.post(MOCK_API_URL + senderPatternApiUrl.senders, mwsSuccess(mocks.senderPatternSenders)),
  rest.get(MOCK_API_URL + senderPatternApiUrl.sender(":id"), mwsSuccess(mocks.senderPatternSender)),
  rest.post(
    MOCK_API_URL + senderPatternApiUrl.sendersExport,
    // mwsBlob(environment.BASENAME + "/fixtures/names.csv", "Names_from_23.05.2023 09_33_27.csv")
    mwsError(400, mocks.errorExcel)
  ),
  rest.post(MOCK_API_URL + senderPatternApiUrl.carriers, mwsSuccess(mocks.senderPatternCarriers)),
  rest.post(MOCK_API_URL + senderPatternApiUrl.smsPatterns, mwsSuccess(mocks.senderPatternSmsPattens)),
  rest.post(
    MOCK_API_URL + senderPatternApiUrl.smsPatternsExport,
    mwsBlob(environment.BASENAME + "/fixtures/names.xlsx", "SmsPattern_from_23.05.2023 09_33_27.xlsx")
    // mwsError(400, mocks.errorExcel)
  ),
  rest.get(MOCK_API_URL + senderPatternApiUrl.smsPatternState, mwsSuccess(mocks.senderPatternSmsPattenStates)),
  rest.post(MOCK_API_URL + senderPatternApiUrl.legalEntities, mwsSuccess(mocks.senderPatternLegalEntities)),

  // senderPatternCheck
  rest.post(
    MOCK_API_URL + senderPatternCheckApiUrl.check,
    mwsSuccess({
      data: {
        state: "ERROR",
        errorMessage: 'Текст "12As" не соответствует шаблону "%d{1,3}"'
      }
    })
  )
];

const simpleMessage = [rest.post(MOCK_API_URL + simpleMessageApiUrl.send, mwsSuccess(mocks.simpleMessages))];

export const handlers = [
  // ...auth,
  // ...user,
  // ...userConfig,
  // ...userAccess,
  // ...client,
  // ...audit,
  // ...campaign,
  // ...campaignTimeConfig
  // ...crm
  // ...campaignExport,
  // ...file,
  // ...list,
  // ...messageLookup,
  // ...preset,
  // ...priceList,
  // ...report,
  // ...senderPattern
  // ...simpleMessage,
];
