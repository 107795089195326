import React, { PropsWithChildren } from "react";
import Spin, { SpinProps } from "antd/es/spin";
import { Color } from "types/interfaces/styles";
import { clsx } from "clsx";
import { getColorClassNames } from "shared/lib/classNames";
import "./Spinner.scss";
import Icon from "../icon/Icon";

interface OwnProps extends SpinProps {
  color?: Color;
  full?: boolean;
  indicator?: React.ReactElement<HTMLElement>;
  id?: string;
  inline?: boolean;
}

type Props = OwnProps;

const Spinner = (props: PropsWithChildren<Props>) => {
  const { color = "white", full, indicator, id, inline, ...spin } = props;

  return (
    <div className={clsx(["spinner", { spinner_full: full, spinner_inline: inline }])} id={id}>
      <Spin
        {...spin}
        indicator={indicator || <Icon name="spinner" size={20} spin color={color} />}
        className={clsx(getColorClassNames(props))}
      />
    </div>
  );
};

export default Spinner;
