import { api } from "./api";
import { FileUrlWrapper, UploadCampaignImageRequest } from "types/models/file";

const prefix = "/file-uploader/public/hub/campaign/v1";
export const fileApiUrl = {
  upload: `${prefix}/image`,
  delete: `${prefix}/image`
};

export const fileApi = api.injectEndpoints({
  endpoints: (build) => ({
    fileUpload: build.mutation<string, UploadCampaignImageRequest>({
      query: (body) => {
        const { campaignId, channel, files } = body;
        let formData = new FormData();

        formData.append("campaignId", campaignId);
        formData.append("channel", channel || "");

        if (files?.length) {
          files?.forEach((element) => formData.append("files", element, element?.name));
        }

        return {
          url: fileApiUrl.upload,
          method: "POST",
          body: formData
        };
      },
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data?.url || ""
    }),
    fileDelete: build.mutation<any, string>({
      query: (url) => ({
        url: fileApiUrl.delete,
        method: "DELETE",
        params: { url }
      })
    })
  })
});

export const { useFileUploadMutation, useFileDeleteMutation } = fileApi;
