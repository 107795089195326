import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "types/enums/loading";
import { RootState } from "../../app/store";

// Types
interface AppHeader {
  title?: string | null;
}

export interface AppState extends AppHeader {
  loading: Loading;
}

const initialState: AppState = {
  title: null,
  loading: Loading.Idle
};

// Reducer
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setHeader(state, { payload }: PayloadAction<AppHeader>) {
      state.title = payload.title || null;
      state.loading = Loading.Succeeded;
    },
    clearApp() {
      return initialState;
    }
  }
});
export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
export const appName = appSlice.name;

// Selectors
export const selectAppState = (state: RootState) => state.app;
export const selectAppTitle = createSelector(selectAppState, (app: AppState) => app.title);
