import { api } from "./api";
import { PageRequest } from "types/interfaces/pageResponse";
import {
  Campaign,
  CampaignMetaDataState,
  CampaignPage,
  CampaignPageWrapper,
  CampaignTimeConfig,
  CampaignTimeConfigWrapper,
  CampaignWrapper,
  ChangeCampaignStateRequest,
  ChangeSourceStateRequest,
  CreateCampaignRequest,
  CreateCampaignTimeConfigRequest,
  ListImportRequest,
  Source,
  SourceErrorRecipientPage,
  SourceErrorRecipientPageWrapper,
  SourceFromText,
  SourceFromTextWrapper,
  SourceRecipientPage,
  SourceRecipientPageWrapper,
  SourceWrapper,
  TextImportRequest
} from "types/models/campaign";
import { CampaignFiltersType, CampaignSortsType } from "types/interfaces/campaign";
import { ISODateToFormat } from "shared/lib/date";

const prefix = "/campaign/client-area/v1";
export const campaignApiUrl = {
  list: `${prefix}/campaigns/all`,
  create: `${prefix}/campaigns/create`,
  update: `${prefix}/campaigns/save`,
  getTimeConfig: `${prefix}/campaign-time-config`,
  updateTimeConfig: `${prefix}/campaign-time-config/create`,
  get: (campaignId: string) => `${prefix}/campaigns/${campaignId}`,
  delete: (campaignId: string) => `${prefix}/campaigns/${campaignId}`,
  changeState: (campaignId: string) => `${prefix}/campaigns/${campaignId}/state`,
  duplicate: (campaignId: string) => `${prefix}/campaigns/${campaignId}/duplicate`,
  recipientsSource: (campaignId: string, sourceId: string) =>
    `${prefix}/campaigns/${campaignId}/source/${sourceId}/recipients/all`,
  recipientsErrorSource: (campaignId: string, sourceId: string) =>
    `${prefix}/campaigns/${campaignId}/source/${sourceId}/error-recipients/all`,
  deleteSource: (campaignId: string, sourceId: string) => `${prefix}/campaigns/${campaignId}/source/${sourceId}`,
  changeStateSource: (campaignId: string, sourceId: string) =>
    `${prefix}/campaigns/${campaignId}/source/${sourceId}/state`,
  importFileSource: (campaignId: string) => `${prefix}/campaigns/${campaignId}/source/import-from-file`,
  importTextSource: (campaignId: string) => `${prefix}/campaigns/${campaignId}/source/import-from-text`,
  importListSource: (campaignId: string) => `${prefix}/campaigns/${campaignId}/source/import-from-list`,
  getTextSource: (campaignId: string, sourceId: string) =>
    `${prefix}/campaigns/${campaignId}/source-from-text/${sourceId}`,
  updateTextSource: (campaignId: string, sourceId: string) =>
    `${prefix}/campaigns/${campaignId}/source-from-text/${sourceId}`
};

export const campaignApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCampaigns: build.query<CampaignPage, PageRequest>({
      query: (
        { page = 0, size = 10, ...other }: PageRequest<CampaignFiltersType, CampaignSortsType> = { page: 0, size: 10 }
      ) => ({
        url: campaignApiUrl.list,
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: CampaignPageWrapper) => response.data,
      providesTags: (result) => [
        ...(result?.content?.map(
          ({ id }) =>
            ({
              type: "Campaign",
              id
            } as const)
        ) || []),
        { type: "Campaign", id: "LIST" }
      ]
    }),
    createCampaign: build.mutation<Campaign, CreateCampaignRequest>({
      query: (queryArg) => ({
        url: campaignApiUrl.create,
        method: "POST",
        body: queryArg
      }),
      transformResponse: (response: CampaignWrapper) => response.data,
      invalidatesTags: (result) => [{ type: "Campaign", id: result?.id }]
    }),
    getCampaign: build.query<Campaign, string>({
      query: (id) => campaignApiUrl.get(id),
      transformResponse: (response: CampaignWrapper) => {
        const res = response.data;
        const sourceList = res.sourceList?.sort((a: Source, b: Source) => {
          const createdAtA = (a?.createdAt && ISODateToFormat(a.createdAt, "T")) || 0;
          const createdAtB = (b?.createdAt && ISODateToFormat(b.createdAt, "T")) || 0;

          return createdAtA > createdAtB ? 1 : -1;
        });

        return { ...res, sourceList };
      },
      providesTags: (_result, _err, id) => [{ type: "Campaign", id }]
    }),
    saveCampaign: build.mutation<Campaign, Campaign>({
      query: (queryArg) => ({
        url: campaignApiUrl.update,
        method: "PUT",
        body: queryArg
      }),
      transformResponse: (response: CampaignWrapper) => response.data,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(campaignApi.util.updateQueryData("getCampaign", id, () => report));
        } catch (error: any) {
          if (error?.error?.status === 409) {
            dispatch(api.util.invalidateTags([{ type: "Campaign", id }]));
          }
        }
      }
    }),
    deleteCampaign: build.mutation<any, string>({
      query: (id) => ({
        url: campaignApiUrl.delete(id),
        method: "DELETE"
      }),
      invalidatesTags: (result) => [{ type: "Campaign", id: result?.id }]
    }),
    changeStateCampaign: build.mutation<Campaign, { id: string; data: ChangeCampaignStateRequest }>({
      query: ({ id, data }) => ({
        url: campaignApiUrl.changeState(id),
        method: "PUT",
        body: data
      }),
      transformResponse: (response: CampaignWrapper) => response.data,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: report } = await queryFulfilled;
          dispatch(campaignApi.util.updateQueryData("getCampaign", id, () => report));
        } catch {}
      },
      invalidatesTags: [{ type: "Campaign", id: "LIST" }]
    }),
    duplicateCampaign: build.mutation<Campaign, string>({
      query: (id) => ({
        url: campaignApiUrl.duplicate(id),
        method: "POST"
      }),
      transformResponse: (response: CampaignWrapper) => response.data,
      invalidatesTags: [{ type: "Campaign", id: "LIST" }]
    }),
    // Recipients
    recipientsSource: build.query<SourceRecipientPage, { campaignId: string; sourceId: string; data?: PageRequest }>({
      query: ({ campaignId, sourceId, data: { page = 0, size = 10, ...other } = { page: 0, size: 10 } }) => ({
        url: campaignApiUrl.recipientsSource(campaignId, sourceId),
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: SourceRecipientPageWrapper) => response.data
    }),
    errorRecipientsSource: build.query<
      SourceErrorRecipientPage,
      { campaignId: string; sourceId: string; data?: PageRequest }
    >({
      query: ({ campaignId, sourceId, data: { page = 0, size = 10, ...other } = { page: 0, size: 10 } }) => ({
        url: campaignApiUrl.recipientsErrorSource(campaignId, sourceId),
        method: "POST",
        body: {
          page,
          size,
          ...other
        }
      }),
      transformResponse: (response: SourceErrorRecipientPageWrapper) => response.data
    }),
    // Source
    deleteSource: build.mutation<any, { campaignId: string; sourceId: string }>({
      query: ({ campaignId, sourceId }) => ({
        url: campaignApiUrl.deleteSource(campaignId, sourceId),
        method: "DELETE"
      }),
      invalidatesTags: (_result, _err, { campaignId }) => [{ type: "Campaign", id: campaignId }]
    }),
    changeStateSource: build.mutation<Source, { campaignId: string; sourceId: string; data: ChangeSourceStateRequest }>(
      {
        query: ({ campaignId, sourceId, data }) => ({
          url: campaignApiUrl.changeStateSource(campaignId, sourceId),
          method: "PUT",
          body: data
        }),
        transformResponse: (response: SourceWrapper) => response.data,
        async onQueryStarted({ campaignId, sourceId }, { dispatch, queryFulfilled }) {
          try {
            const { data: source } = await queryFulfilled;
            dispatch(
              campaignApi.util.updateQueryData("getCampaign", campaignId, (draft: Campaign) => ({
                ...draft,
                sourceList: draft?.sourceList?.map((item: Source) => (item?.id === sourceId ? source : item))
              }))
            );
          } catch {}
        }
      }
    ),
    importFileSource: build.mutation<Source, { campaignId: string; recipientFile: File; stop?: boolean }>({
      query: ({ campaignId, recipientFile, stop = false }) => {
        let formParams = new FormData();
        formParams.append("recipientFile", recipientFile, recipientFile?.name);

        return {
          url: campaignApiUrl.importFileSource(campaignId),
          method: "POST",
          body: formParams,
          params: { stop }
        };
      },
      transformResponse: (response: SourceWrapper) => response.data,
      async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
        try {
          const { data: source } = await queryFulfilled;
          dispatch(
            campaignApi.util.updateQueryData("getCampaign", campaignId, (draft: Campaign) => ({
              ...draft,
              metaData: { ...draft?.metaData, state: CampaignMetaDataState.RECALCULATE },
              sourceList: [...(draft?.sourceList || []), source]
            }))
          );
        } catch {}
      }
    }),
    importTextSource: build.mutation<Source, { campaignId: string; body: TextImportRequest; stop: boolean }>({
      query: ({ campaignId, body, stop = false }) => ({
        url: campaignApiUrl.importTextSource(campaignId),
        method: "POST",
        body: body,
        params: { stop }
      }),
      transformResponse: (response: SourceWrapper) => response.data,
      async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
        try {
          const { data: source } = await queryFulfilled;
          dispatch(
            campaignApi.util.updateQueryData("getCampaign", campaignId, (draft: Campaign) => ({
              ...draft,
              metaData: { ...draft?.metaData, state: CampaignMetaDataState.RECALCULATE },
              sourceList: [...(draft?.sourceList || []), source]
            }))
          );
        } catch {}
      }
    }),
    importListSource: build.mutation<Source, { campaignId: string; body: ListImportRequest; stop: boolean }>({
      query: ({ campaignId, body, stop = false }) => ({
        url: campaignApiUrl.importListSource(campaignId),
        method: "POST",
        body: body,
        params: { stop }
      }),
      transformResponse: (response: SourceWrapper) => response.data,
      async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
        try {
          const { data: source } = await queryFulfilled;
          dispatch(
            campaignApi.util.updateQueryData("getCampaign", campaignId, (draft: Campaign) => ({
              ...draft,
              metaData: { ...draft?.metaData, state: CampaignMetaDataState.RECALCULATE },
              sourceList: [...(draft?.sourceList || []), source]
            }))
          );
        } catch {}
      }
    }),
    getTextSource: build.query<SourceFromText, { campaignId: string; sourceId: string }>({
      query: ({ campaignId, sourceId }) => campaignApiUrl.getTextSource(campaignId, sourceId),
      transformResponse: (response: SourceFromTextWrapper) => response.data
    }),
    updateTextSource: build.mutation<Source, { campaignId: string; sourceId: string; data: TextImportRequest }>({
      query: ({ campaignId, sourceId, data }) => ({
        url: campaignApiUrl.updateTextSource(campaignId, sourceId),
        method: "PUT",
        body: data
      }),
      transformResponse: (response: SourceWrapper) => response.data,
      async onQueryStarted({ campaignId, sourceId }, { dispatch, queryFulfilled }) {
        try {
          const { data: source } = await queryFulfilled;
          dispatch(
            campaignApi.util.updateQueryData("getCampaign", campaignId, (draft: Campaign) => ({
              ...draft,
              sourceList: draft?.sourceList?.map((item: Source) => (item?.id === sourceId ? source : item))
            }))
          );
        } catch {}
      }
    }),
    getTimeConfig: build.query<CampaignTimeConfig, void>({
      query: () => campaignApiUrl.getTimeConfig,
      transformResponse: (response: CampaignTimeConfigWrapper) => response.data,
      providesTags: ["CampaignTimeConfig"]
    }),
    updateTimeConfig: build.mutation<CampaignTimeConfig, CreateCampaignTimeConfigRequest>({
      query: (data) => ({
        url: campaignApiUrl.updateTimeConfig,
        method: "POST",
        body: data
      }),
      transformResponse: (response: CampaignTimeConfigWrapper) => response.data,
      invalidatesTags: ["CampaignTimeConfig"]
    })
  }),
  overrideExisting: false
});

export const {
  useGetAllCampaignsQuery,
  useGetCampaignQuery,
  useSaveCampaignMutation,
  useCreateCampaignMutation,
  useChangeStateCampaignMutation,
  useDeleteCampaignMutation,
  useDuplicateCampaignMutation,
  useImportFileSourceMutation,
  useImportListSourceMutation,
  useImportTextSourceMutation,
  useUpdateTextSourceMutation,
  useDeleteSourceMutation,
  useChangeStateSourceMutation,
  useLazyGetAllCampaignsQuery,
  useLazyRecipientsSourceQuery,
  useLazyErrorRecipientsSourceQuery,
  useLazyGetTextSourceQuery,
  useGetTimeConfigQuery,
  useUpdateTimeConfigMutation
} = campaignApi;
