import { Actor } from "types/models/audit/actor";
import { AuditEvent } from "types/models/audit/auditEvent";
import { Section } from "types/models/audit/section";
import { Action } from "types/models/audit/action";

export const getEventMock = (id: string, data?: Partial<AuditEvent>): AuditEvent => ({
  id,
  actionAt: "2020-10-04T15:04:24.045Z",
  actor: getActorMock("1", { login: "Long long-logn  long-logn long-logn long-logn long-logn user" }),
  section: getSectionMock(),
  details:
    "<b>Базовый текст сообщения:</b><br>+79167776655\n" +
    "+79267776655\n" +
    "+79057776655\n" +
    "+79017776655\n" +
    "+79037776655<br> → <br>авыывфа",
  action: getActionMock(),
  subject: {
    name: "Рассылка от 12.02.2022",
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  },
  ip: "127.0.0.1"
});

export const getActorMock = (id: string, { login = "Test" } = { login: "Test" }): Actor => ({
  id,
  login
});

export const getSectionMock = (
  { name = "CAMPAIGN", desc = "Рассылки" } = {
    name: "CAMPAIGN",
    desc: "Рассылки"
  }
): Section => ({
  name,
  desc
});

export const getActionMock = (
  { name = "CREATE", desc = "Создание" } = {
    name: "CREATE",
    desc: "Создание"
  }
): Action => ({
  name,
  desc
});

export default {
  events: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getEventMock("1", {}),
        getEventMock("2", {}),
        getEventMock("3", {}),
        getEventMock("4", {}),
        getEventMock("5", {}),
        getEventMock("6", {}),
        getEventMock("7", {}),
        getEventMock("8", {}),
        getEventMock("9", {}),
        getEventMock("10", {})
      ]
    }
  },
  actors: {
    data: [
      getActorMock("1", { login: "Test" }),
      getActorMock("2", { login: "Test" }),
      getActorMock("3", { login: "Test" }),
      getActorMock("4", { login: "Test" }),
      getActorMock("5", { login: "Test" }),
      getActorMock("6", { login: "Test" }),
      getActorMock("7", { login: "Test" }),
      getActorMock("8", { login: "Test" }),
      getActorMock("9", { login: "Test" }),
      getActorMock("10", { login: "Test" })
    ]
  },
  section: {
    data: [
      getSectionMock({ name: "CREATE", desc: "Создание" }),
      getSectionMock({ name: "CAMPAIGN", desc: "Рассылки" }),
      getSectionMock({ name: "REPORT", desc: "Отчеты" }),
      getSectionMock({ name: "AUTH", desc: "Аутентификация" })
    ]
  },
  eventsDownloadUrl: {
    data: {
      url: "https://url.com"
    }
  }
};
