import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Loading } from "types/enums/loading";

// Types
export interface CampaignState {
  dataIsEdited?: boolean;
  loading: Loading;
}

const initialState: CampaignState = {
  dataIsEdited: false,
  loading: Loading.Idle
};

// Reducer
export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setDataIsEdited(state, { payload }: PayloadAction<boolean>) {
      state.dataIsEdited = payload || undefined;
    },

    clear: () => initialState
  }
});
export const campaignActions = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;
export const campaignName = campaignSlice.name;

// Selectors
export const selectCampaignDataIsEdited = (state: RootState) => state.campaign.dataIsEdited;
