import { Result, SendMessageResult } from "types/models/simple-messsage";

export const getSimpleMessageMock = (data: Partial<SendMessageResult> = {}): SendMessageResult => ({
  msisdn: "79003002211",
  result: Result.Success,
  ...data
});

export default {
  simpleMessages: {
    data: {
      results: [
        getSimpleMessageMock({ msisdn: "79003002211" }),
        getSimpleMessageMock({ msisdn: "79003002222" }),
        getSimpleMessageMock({ msisdn: "79003002233" }),
        getSimpleMessageMock({ msisdn: "79003002244" }),
        getSimpleMessageMock({ msisdn: "79003002255", result: Result.Failed }),
        getSimpleMessageMock({ msisdn: "79003002266", result: Result.Failed }),
        getSimpleMessageMock({ msisdn: "79003002277", result: Result.Failed }),
        getSimpleMessageMock({ msisdn: "79003002288" }),
        getSimpleMessageMock({ msisdn: "79003002299" }),
        getSimpleMessageMock({ msisdn: "79003002200" })
      ]
    }
  }
};
