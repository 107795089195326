import React, { PropsWithChildren } from "react";
import Fallback from "shared/ui/fallback/Fallback";

const MessageLookupBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'message-lookup' */
      /* webpackPrefetch: 10 */
      "./MessageLookupRouter"
    )
);

const MessageLookupModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <MessageLookupBundle />
  </React.Suspense>
);

export default MessageLookupModule;
