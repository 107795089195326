import { api } from "./api";
import { SendMessageRequest, SendMessageResponse, SendMessageResponseWrapper } from "types/models/simple-messsage";

const prefix = "/simple-message-sender/client-area/v1";
export const simpleMessageApiUrl = {
  send: `${prefix}/send-message`
};

export const simpleMessageApi = api.injectEndpoints({
  endpoints: (build) => ({
    simpleMessageCreate: build.mutation<SendMessageResponse, SendMessageRequest>({
      query: (body) => ({
        url: simpleMessageApiUrl.send,
        method: "POST",
        body
      }),
      transformResponse: (response: SendMessageResponseWrapper, meta, arg) => response?.data
    })
  })
});

export const { useSimpleMessageCreateMutation } = simpleMessageApi;
