import { api } from "./api";
import { PageRequest } from "types/interfaces/pageResponse";
import { AuditFiltersType, AuditSortsType } from "types/interfaces/audit";
import { MessagePageWrapper } from "types/models/message-lookup/messagePageWrapper";
import { MessagePage } from "types/models/message-lookup/messagePage";
import { RowLimitWrapper } from "types/models/message-lookup/rowLimitWrapper";
import { MessageLookupFiltersType, MessageLookupSortsType } from "types/interfaces/messageLookup";

const prefix = "/message-lookup/hub/v1";
export const messageLookupApiUrl = {
  messages: `${prefix}/messages/all`,
  download: `${prefix}/messages/export/download`,
  countLimit: `${prefix}/messages/export/count-limit`
};

export const messageLookupApi = api.injectEndpoints({
  endpoints: (build) => ({
    messageLookupMessages: build.query<MessagePage, PageRequest<MessageLookupFiltersType, MessageLookupSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: messageLookupApiUrl.messages,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: MessagePageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ msisdn }) => ({ type: "MessageLookup", id: msisdn } as const)) || []),
        { type: "MessageLookup", id: "LIST" }
      ]
    }),
    messageLookupCountLimit: build.query<number, void>({
      query: () => messageLookupApiUrl.countLimit,
      transformResponse: (response: RowLimitWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "MessageLookup", id: "COUNT" }]
    }),
    messageLookupDownload: build.mutation<Blob, PageRequest<MessageLookupFiltersType, MessageLookupSortsType>>({
      query: ({ page = 0, size = 10, ...other } = { page: 0, size: 10 }) => ({
        url: messageLookupApiUrl.download,
        method: "POST",
        body: { page, size, ...other },
        responseHandler: async (response) => await (response?.status === 200 ? response.blob() : response.json()),
        cache: "no-cache"
      })
    })
  }),
  overrideExisting: false
});

export const { useMessageLookupMessagesQuery, useMessageLookupDownloadMutation, useMessageLookupCountLimitQuery } =
  messageLookupApi;
