import React, { PropsWithChildren } from "react";
import Fallback from "shared/ui/fallback/Fallback";

const PresetBundle = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'preset' */
      /* webpackPrefetch: 10 */
      "./PresetRouter"
    )
);

const PresetModule = () => (
  <React.Suspense fallback={<Fallback />}>
    <PresetBundle />
  </React.Suspense>
);

export default PresetModule;
