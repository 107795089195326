import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loading } from "types/enums/loading";
import { Token } from "types/interfaces/auth";
import { loadStorage, removeStorage, saveStorage } from "shared/lib/localStorage";
import { RootState } from "../../app/store";
import { StorageName } from "types/enums/storageName";
import Sentry from "shared/lib/sentry";

// Types
export interface AuthState {
  data?: Token | null;
  loading: Loading;
  clientId: string;
}

const initialState: AuthState = {
  data: loadStorage<Token>(StorageName.Auth),
  loading: Loading.Idle,
  clientId: "ui"
};

// Reducer
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, { payload }: PayloadAction<Token>) {
      state.data = payload;
      saveStorage(StorageName.Auth, payload);
      state.loading = Loading.Succeeded;
    },
    clearAuth(state) {
      state.data = undefined;
      state.loading = Loading.Idle;
      removeStorage(StorageName.Auth);
      removeStorage(StorageName.Access);
      Sentry.clearUser();
    }
  }
});
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
export const authName = authSlice.name;

// Selectors
export const selectAuthState = (state: RootState) => state.auth;
export const selectAuth = createSelector(selectAuthState, (auth: AuthState) => auth.data);
export const selectClientId = createSelector(selectAuthState, (auth: AuthState) => auth.clientId);
