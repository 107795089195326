import { CampaignState, CampaignType, ScenarioItemType } from "types/enums/campaign";
import {
  Campaign,
  CampaignMetaDataState,
  ScenarioItem,
  Source,
  SourceErrorRecipient,
  SourceRecipient,
  SourceState,
  SourceType
} from "types/models/campaign";
import { getRandomInt } from "shared/lib/helpers";

export const getCampaignMock = (id: string, { state = CampaignState.DRAFT, type = CampaignType.SIMPLE }): Campaign => ({
  id,
  state,
  timetable: {
    startDate: "2020-10-19",
    finishDate: "2020-10-20",
    recipientTimeZone: true,
    defaultStartTime: "00:01",
    defaultStopTime: "23:59",
    days: [
      {
        dayOfWeek: "mon",
        startTime: "00:01",
        stopTime: "23:59"
      }
    ]
  },
  type,
  name: "Наименование рассылки",
  createdAt: "2021-11-26T00:00:00.00+18:00",
  updatedAt: "2021-11-26T00:00:00.00-18:00",
  defaultText:
    "This is an {{immutable}} entity: Superman. Deleting any characters will delete the entire entity. Adding characters will remove the entity from the range.\n" +
    "\n" +
    "This is a {{mutable}} entity: Batman. Characters may be added and removed.\n" +
    "\n" +
    'This is a {{segmented}} entity: Green Lantern. Deleting any characters will delete the current "segment" from the range. Adding characters will remove the entire entity from the range.',
  sourceList: [
    getSourceMock("1", { type: SourceType.REQUEST }),
    getSourceMock("2", { state: SourceState.CREATED, type: SourceType.FILE }),
    getSourceMock("3", { state: SourceState.CREATED_WITH_ERROR, type: SourceType.FILE, stop: true }),
    getSourceMock("4", { type: SourceType.LIST })
  ],
  metaData: {
    state: CampaignMetaDataState.DONE,
    recipientsCount: 0,
    smsPartsCount: 0
  },
  maxSendingSpeed: 0,
  owner: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Петр",
    department: {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      name: "Петр"
    },
    client: {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      name: "Петр"
    }
  },
  scenario: [
    getScenarioMock({ type: ScenarioItemType.WHATSAPP }),
    getScenarioMock({ type: ScenarioItemType.PUSH }),
    getScenarioMock({ type: ScenarioItemType.VIBER }),
    getScenarioMock({ type: ScenarioItemType.VK }),
    getScenarioMock()
  ],
  statistic: {
    inQueue: 100,
    sent: 100,
    delivered: 100,
    notDelivered: 100,
    seen: 100
  }
});

export const getScenarioMock = ({ type = ScenarioItemType.SMS } = {}): ScenarioItem => ({
  type,
  sender: "Sender",
  text:
    "This is an {{immutable}} entity: Superman. Deleting any characters will delete the entire entity. Adding characters will remove the entity from the range.\n" +
    "\n" +
    "This is a {{mutable}} entity: Batman. Characters may be added and removed.",
  ttl: 3600,
  attachments: [
    {
      type: "IMAGE",
      size: 199123123,
      name: "Война_токов.png",
      content: "https://avatars.mds.yandex.net/get-kinopoisk-image/1946459/994f568a-dc50-4e2d-8bba-1747e59fefe2/300x450"
    }
  ],
  buttons: [
    {
      caption: "button text",
      action: "https://domain.ru"
    }
  ]
});

export const getSourceMock = (
  id: string,
  { state = SourceState.CREATED, type = SourceType.FILE, stop = false }
): Source => ({
  id,
  name: "Список абонентов N1.csv",
  state,
  type,
  stop,
  createdAt: "2020-08-23T13:31:45.026Z",
  updatedAt: "2020-08-23T13:31:45.026Z",
  recipientsCount: 999,
  errorRecipientCount: 777,
  tags: ["immutable", "mutable", "segmented"]
});

export const getRecipientMock = (msisdn: string = "79991234567"): SourceRecipient => ({
  msisdn,
  scenario: [
    {
      type: ScenarioItemType.SMS,
      text: "Текст SMS сообщения"
    }
  ]
});

export const getErrorRecipientMock = (
  rowNumber: number,
  msisdn = "79991234567",
  errorMessage = "Сообщение об ошибке"
): SourceErrorRecipient => ({ msisdn, rowNumber, errorMessage });

export default {
  campaigns: {
    data: {
      totalElements: 100,
      size: 10,
      page: 0,
      content: [
        getCampaignMock("1", {}),
        getCampaignMock("2", {}),
        getCampaignMock("3", {}),
        getCampaignMock("4", {}),
        getCampaignMock("5", {}),
        getCampaignMock("6", {}),
        getCampaignMock("7", {}),
        getCampaignMock("8", {}),
        getCampaignMock("9", {}),
        getCampaignMock("10", {})
      ]
    }
  },
  campaign: { data: getCampaignMock("1", { state: CampaignState.FINISHED }) },
  campaignIndividual: { data: getCampaignMock("2", { state: CampaignState.DRAFT, type: CampaignType.INDIVIDUAL }) },
  campaignTags: { data: getCampaignMock("3", { state: CampaignState.DRAFT, type: CampaignType.TAGS }) },
  sourceFile: {
    data: getSourceMock("" + getRandomInt(), {})
  },
  sourceText: {
    data: getSourceMock("" + getRandomInt(), { type: SourceType.REQUEST })
  },
  sourceList: {
    data: getSourceMock("" + getRandomInt(), { type: SourceType.LIST })
  },
  sourceStopFile: {
    data: getSourceMock("" + getRandomInt(), { stop: true })
  },
  sourceStopText: {
    data: getSourceMock("" + getRandomInt(), { type: SourceType.REQUEST, stop: true })
  },
  sourceStopList: {
    data: getSourceMock("" + getRandomInt(), { type: SourceType.LIST, stop: true })
  },
  sourceRecipients: {
    data: {
      totalElements: 4,
      page: 0,
      size: 10,
      content: [getRecipientMock(), getRecipientMock(), getRecipientMock(), getRecipientMock()]
    }
  },
  sourceErrorRecipients: {
    data: {
      totalElements: 4,
      page: 0,
      size: 10,
      content: [getErrorRecipientMock(1), getErrorRecipientMock(2), getErrorRecipientMock(3), getErrorRecipientMock(4)]
    }
  },
  sourceFromText: {
    data: {
      msisdns: ["+79001112222", "+79001112223", "+79001112224", "+79001112225", "+79001112226", "+79001112227"]
    }
  }
};
