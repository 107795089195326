import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Env } from "types/enums/env";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import environment from "./shared/config/environment";
import App from "./app";
import { store } from "./app/store";
import Sentry from "./shared/lib/sentry";

console.log("mgfn-hub-ui", environment.VERSION);
Sentry.init();

const renderDev = async () => {
  try {
    const { worker } = require("shared/api/mocks/browser");
    await worker?.start({
      serviceWorker: {
        url: environment.BASENAME + "/mockServiceWorker.js"
      }
    });
  } catch (error) {
    console.log(error);
  }

  // Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals(console.log);
  console.log("config:", environment);

  render();
};

const render = () => {
  const container = document.getElementById("root")!;

  if (!container) {
    throw new Error("Failed to find the root element");
  }

  if (container) {
    container.setAttribute("data-version", environment.VERSION);
  }

  const root = createRoot(container);

  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={environment.BASENAME}>
        <App />
      </BrowserRouter>
    </Provider>
    // </React.StrictMode>
  );
};

if ([Env.Local].includes(environment?.ENV)) {
  renderDev();
} else {
  render();
}
