import { api } from "./api";
import { PageRequest } from "types/interfaces/pageResponse";
import {
  CreateRecipientListRequest,
  FileUrl,
  FileUrlWrapper,
  RecipientList,
  RecipientListPage,
  RecipientListPageWrapper,
  RecipientListWrapper,
  RecipientPage,
  RecipientPageWrapper
} from "types/models/list";
import { ListType } from "types/enums/list";
import { ListFiltersType, ListSortsType, RecipientsFiltersType } from "types/interfaces/list";

const prefix = "/recipient-list/public/hub/v1";
export const listApiUrl = {
  lists: `${prefix}/list/all`,
  listCreate: `${prefix}/list/create`,
  list: (id: string) => `${prefix}/list/${id}`,
  listDelete: (id: string) => `${prefix}/list/${id}`,
  listRecipients: (id: string) => `${prefix}/list/${id}/recipient/all`,
  listRecipientUrl: (id: string) => `${prefix}/list/${id}/recipient/download-url`,
  listErrorUrl: (id: string) => `${prefix}/list/${id}/error/download-url`,
  listIgnoreErrors: (id: string) => `${prefix}/list/${id}/ignore-errors`
};

export const listApi = api.injectEndpoints({
  endpoints: (build) => ({
    lists: build.query<RecipientListPage, PageRequest>({
      query: (
        { page = 0, size = 10, ...other }: PageRequest<ListFiltersType, ListSortsType> = { page: 0, size: 10 }
      ) => ({
        url: listApiUrl.lists,
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: RecipientListPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "List", id } as const)) || []),
        { type: "List", id: "LIST" }
      ]
    }),
    listCreate: build.mutation<RecipientList, CreateRecipientListRequest>({
      query: (queryArg) => {
        const { files, name, msisdns, type, description } = queryArg;
        let formParams = new FormData();

        formParams.append("type", type || ListType.SIMPLE);
        formParams.append("name", name || "");

        if (description) {
          formParams.append("description", description);
        }
        if (files?.length) {
          files?.forEach((element) => formParams.append("files", element, element.name));
        }
        if (msisdns?.length) {
          formParams.append("msisdns", msisdns.join(","));
        }

        return {
          url: listApiUrl.listCreate,
          method: "POST",
          body: formParams
        };
      },
      transformResponse: (response: RecipientListWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [
        { type: "List", id: _result?.id },
        { type: "List", id: "LIST" }
      ]
    }),
    list: build.query<RecipientList, string>({
      query: (id) => ({
        url: listApiUrl.list(id)
      }),
      transformResponse: (response: RecipientListWrapper, meta, arg) => response.data,
      providesTags: (_result, _err, id) => [{ type: "List", id }]
    }),
    listDelete: build.mutation<any, string>({
      query: (id) => ({
        url: listApiUrl.listDelete(id),
        method: "DELETE"
      }),
      invalidatesTags: (_result, _err, _req) => [{ type: "List", id: "LIST" }]
    }),
    listRecipients: build.query<RecipientPage, { id: string; data: PageRequest<RecipientsFiltersType> }>({
      query: ({ id, data: { page = 0, size = 10, ...other } = { page: 0, size: 10 } }) => ({
        url: listApiUrl.listRecipients(id),
        method: "POST",
        body: { page, size, ...other }
      }),
      transformResponse: (response: RecipientPageWrapper, meta, arg) => response.data,
      providesTags: (_result, _err) => [
        ...(_result?.content?.map(({ id }) => ({ type: "ListRecipient", id } as const)) || []),
        { type: "ListRecipient", id: "LIST" }
      ]
    }),
    listRecipientUrl: build.mutation<FileUrl, string>({
      query: (id) => ({
        url: listApiUrl.listRecipientUrl(id),
        method: "GET"
      }),
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data
    }),
    listErrorUrl: build.mutation<FileUrl, string>({
      query: (id) => ({
        url: listApiUrl.listErrorUrl(id),
        method: "GET"
      }),
      transformResponse: (response: FileUrlWrapper, meta, arg) => response.data
    }),
    listIgnoreErrors: build.mutation<RecipientList, string>({
      query: (id) => ({
        url: listApiUrl.listIgnoreErrors(id),
        method: "POST"
      }),
      transformResponse: (response: RecipientListWrapper, meta, arg) => response.data,
      invalidatesTags: (_result, _err, _req) => [{ type: "List", id: _result?.id }]
    })
  }),
  overrideExisting: false
});

export const {
  useListsQuery,
  useListQuery,
  useListCreateMutation,
  useListDeleteMutation,
  useListRecipientsQuery,
  useListRecipientUrlMutation,
  useListErrorUrlMutation,
  useListIgnoreErrorsMutation
} = listApi;
